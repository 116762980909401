<template>
  <section>
    <div id="discussSection" class="platform__title">
      <p class="title">ШКОЛА <span>«МОСКОВСКИЙ ИССЛЕДОВАТЕЛЬ»</span></p>
      <blockquote class="left">Школа &laquo;Московский исследователь&raquo;&nbsp;&mdash; это дискуссионная площадка, позволяющая получить качественные и&nbsp;современные знания по&nbsp;востребованным направлениям в&nbsp;области клинических исследований, а&nbsp;также повысить свой уровень компетенций. В&nbsp;рамках Школы на&nbsp;ежемесячной основе в&nbsp;прямом эфире мы&nbsp;обсуждаем самые актуальные и&nbsp;сложные вопросы организации и&nbsp;проведения клинических исследований. Гостями нашей студии являются ведущие эксперты отрасли&nbsp;&mdash; Главные исследователи, представители регуляторных органов и&nbsp;этических комитетов, специалисты фармацевтических компаний и&nbsp;контрактно-исследовательских организаций, независимые эксперты и&nbsp;другие специалисты. Приглашаем всех сотрудников медицинских организаций системы здравоохранения города Москвы, вовлеченных в&nbsp;проведение клинических исследований, принять активное участие в&nbsp;работе Школы.</blockquote>
    </div>
    
  </section>
</template>


<style lang="scss" scoped>
.left {
  text-align: left;
}
</style>