<template>
  <div v-if="event.video" class="videoPage" id="media" data-nav-section>

    <div class="wrapper">
      <nav class="video-page__breadcrumbs">
        <router-link to="/#school" class="breadcrumbs__link">Школа</router-link>
        <router-link to="/events" class="breadcrumbs__link">Прошедшие мероприятия</router-link>
        <span class="breadcrumbs__link">{{ event?.title }}</span>
      </nav>

      <template v-if="event?.video?.includes('iframe')">
        <div class="videoFrame" v-html="event.video"></div>
      </template>

      <video v-else ref="video" id="video" class="videoPlayer" controls="controls" preload='metadata' :src="event.video"
        :poster="event?.videoPreview">
      </video>
      <div v-if="event.videoAnons" @click="goToTimestamp($event)" class="timecodes" v-html="prepareTimecodes()"></div>
    </div>
    
    <nav class="video-page__breadcrumbs video-page__breadcrumbs--bottom">
      <div class="wrapper">
        <router-link to="/#media" class="breadcrumbs__link">Медиа</router-link>
        <router-link to="/events" class="breadcrumbs__link">Прошедшие мероприятия</router-link>
        <span class="breadcrumbs__link">{{ event?.title }}</span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "VideoPage",
  created() {
  },
  computed: {
    event() {
      const { gcp, id } = this.$route.params;
      if (!id) return false;
      let result = false;
      if (gcp && gcp !== 'false') {
        result = this.$store.getters['events/getGcpEventFromCacheById'](id);
      } else {
        result = this.$store.getters['events/getEventFromCacheById'](id);
      }

      return result;
    }
  },
  methods: {
    convertTimestampToSeconds(rawTimecode) {
      rawTimecode.split(':');
      return rawTimecode
        .split(':')
        .reduceRight((sum, current, i, arr) => {
          current = Number(current);
          if (arr.length === 2) {
            if (i === 0) current *= 60;
            return current + sum;
          }
          if (arr.length === 3) {
            if (i === 0) current *= 60 * 60;
            if (i === 1) current *= 60;
            return current + sum;
          }
        }, 0);
    },
    goToTimestamp($event) {
      const target = $event.target;
      if (!target.classList.contains(this.TIMECODE_CLASS)) return;
      const seconds = this.convertTimestampToSeconds(target.innerText.trim());
      this.$refs.video.currentTime = seconds;
      this.$refs.video.play();
    },
    prepareTimecodes() {
      let source = this.event.videoAnons.replaceAll('\n', '<br/>');
      const regex = /(\d*:\d*(:\d*)?)\s/ig
      source = source.replaceAll(regex, `<a class="${this.TIMECODE_CLASS}">$1</a> `)
      return source;
    },
  },
  data() {
    return {
      TIMECODE_CLASS: 'timecode',
      text: `
        <div id="timecodes">
            <p>00:12 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, rerum.</p>
            <p>00:01:11 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, rerum.</p>
            <p>01:22:33 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, rerum.</p>
        </div>`,
    }
  }
}
</script>

<style lang="scss">
.videoPage {
  margin: auto;
  padding-top: 140px;

  .main-white {
    min-height: 0;
  }

  .timecodes {
    margin-top: 15px;
  }

}

.videoPlayer {
  max-width: 100%;
}

.videoFrame {
  iframe {
    width: 100%;
  }
}

.timecode {
  cursor: pointer;
}

/* @media (max-width: 720px) {
    padding: 75px 30px;
  } */


.video-page__breadcrumbs {
  margin-bottom: 60px;

  &--bottom {
    margin-top: 100px;
    padding: 20px 0;
    border-top: 1px solid rgba(#16171E, 0.2);
    border-bottom: 1px solid rgba(#16171E, 0.2);
    margin-bottom: 0;
  }
}
</style>