<template>
    <div class="article-page" id="materials" data-nav-section>

        <div class="article-page__wrap">
            <div class="wrapper">
                <nav class="article-page__breadcrumbs">
                    <router-link to="/#materials" class="breadcrumbs__link">Полезные материалы</router-link>
                    <router-link :to="`/instruction/${id}`"
                        class="breadcrumbs__link">{{ currentInstruction.name }}</router-link>
                    <span class="breadcrumbs__link">{{ currentArticle?.name }}</span>
                </nav>

                <div class="article-page__content-wrap"
                    v-if="currentArticle.description && currentArticle.description !== 'undefined'">
                    <aside class="article-page__sidebar">
                        <div class="article-page__nav">
                            <div class="article-page__nav-title">
                                Оглавление
                            </div>
                            <ul class="article-page__nav-list">
                                <li class="article-page__nav-item" v-for="heading in navHeadings" :key="heading.id">
                                    <a :href="`#${heading.id}`" v-smooth-scroll class="article-page__nav-link"
                                        :class="heading.active && 'article-page__nav-link--active'">
                                        {{ heading.label }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>

                    <div class="article-page__content">
                        <ul class="article-page__meta"
                            v-if="currentArticle.publications.length || currentArticle.materials.length">
                            <li class="article-page__meta-item" v-if="currentArticle.publications.length">
                                <a href="#publications" class="article-page__meta-link" v-smooth-scroll>
                                    <template
                                        v-if="currentInstruction?.name === 'Нормативные документы'">документы</template>
                                    <template v-else>научные статьи</template>
                                </a>
                            </li>

                            <li class="article-page__meta-item" v-if="currentArticle.materials.length">
                                <a href="#media" class="article-page__meta-link" v-smooth-scroll>материалы</a>
                            </li>
                        </ul>

                        <div ref="articleWrap" class="article-page__article" v-html="currentArticle.description"></div>
                    </div>
                </div>

                <InProgress v-else />
            </div>

            <ArticlePublications class="article-page__article-publications" id="publications"
                :title="currentInstruction?.name === 'Нормативные документы' ? 'Документы' : 'Научные статьи'"
                v-if="currentArticle.publications.length" :publications="currentArticle.publications" />

            <ArticleMedia class="article-page__article-media" id="media" v-if="currentArticle.materials.length"
                :media="currentArticle.materials" />

        </div>

        <nav class="article-page__breadcrumbs article-page__breadcrumbs--bottom">
            <div class="wrapper">
                <router-link to="/" class="breadcrumbs__link">Полезные материалы</router-link>
                <router-link :to="`/instruction/${id}`"
                    class="breadcrumbs__link">{{ currentInstruction.name }}</router-link>
                <span class="breadcrumbs__link">{{ currentArticle?.name }}</span>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, onMounted, ref } from 'vue';

import InProgress from '../components/InProgress'
import ArticlePublications from '@/components/Article/Publications'
import ArticleMedia from '@/components/Article/Media'

const store = useStore()
const route = useRoute()
const { id, articleID } = route.params

const navHeadings = ref([])
const articleWrap = ref()

const currentInstruction = computed(() => {

    let result = false

    result = store.getters['materials/getInstructionsFromCacheById'](id)

    return result
})

const currentArticle = computed(() => {
    const article = currentInstruction.value.documents.find(el => el.id === articleID);

    const parser = new DOMParser()
    const doc = parser.parseFromString(article.description, 'text/html')
    const headings = doc.querySelectorAll('h1, h2, h3, h4')

    headings.forEach((heading, index) => heading.id = `heading_${index}`)

    article.description = doc.body.innerHTML

    return article
})

const createObserver = (el, index) => {
    const handleIntersect = (e) => {
        if (e[0].isIntersecting) {
            navHeadings.value[index].active = true
            return
        }

        navHeadings.value[index].active = false
    }

    const observer = new IntersectionObserver(handleIntersect, {
        root: null,
        rootMargin: '0px',
        threshold: 0.05
    })

    observer.observe(el)
}

onMounted(() => {
    if (!articleWrap.value) return

    const headings = articleWrap.value.querySelectorAll('h1, h2, h3, h4')

    headings.forEach((h, index) => {
        const { id } = h

        createObserver(h, index)

        navHeadings.value[index] = {
            label: h.textContent,
            active: false,
            id,
        }
    })
})

</script>

<style lang="scss" scoped>
.article-page {
    &__wrap {
        padding: 100px 0;

        @media (max-width: 600px) {
            padding: 40px 0;
        }
    }

    &__breadcrumbs {
        margin-bottom: 60px;

        &--bottom {
            padding: 20px 0;
            border-top: 1px solid rgba(#16171E, 0.2);
            border-bottom: 1px solid rgba(#16171E, 0.2);
            margin-bottom: 0;
        }
    }

    &__content-wrap {
        display: grid;
        grid-template-columns: 320px auto;
        gap: 40px;

        @media (max-width: 1200px) {
            grid-template-columns: 280px auto;
        }

        @media (max-width: 991px) {
            grid-template-columns: 1fr;
        }
    }

    &__nav {
        background: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        position: sticky;
        top: 90px;

        &-title {
            font-size: 18px;
            padding: 16px 24px;
        }

        &-list {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &-link {
            display: flex;
            text-decoration: none;
            padding: 20px 25px;
            font-size: 12px;
            line-height: 150%;
            border-left: 3px solid transparent;
            transition: 500ms;

            &--active {
                background: rgba(18, 116, 224, 0.1);
                transition: 500ms;
                border-color: #1274E0;
                box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);

                @media (max-width: 991px) {
                    border-color: transparent;
                    background: transparent;
                    box-shadow: none;
                }
            }
        }
    }

    &__meta {
        display: flex;
        align-items: center;
        gap: 15px;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 30px;

        &-link {
            padding: 12px 20px;
            background: rgba(18, 116, 224, 0.1);
            border-radius: 5px;
            text-decoration: none;
            color: #1274E0;
            display: flex;
            align-items: center;
            gap: 5px;


            &::after {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8L4 4.5L1 1' stroke='%231274E0' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                width: 10px;
                height: 10px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    &__article {
        font-size: 16px;
        margin-bottom: 100px;

        &:deep(h1) {
            font-size: 40px;
            line-height: 120%;
        }

        &:deep(h2) {
            font-size: 26px;
            line-height: 120%;
        }
    }
}
</style>