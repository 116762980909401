<template>
    <section class="article-media">
        <div class="wrapper">
            <div class="article-media__wrap">
                <h2 class="article-media__heading">Материалы</h2>

                <Swiper :slides-per-view="'auto'" :space-between="20"
                    :navigation="{ nextEl: '.article-media__next', prevEl: '.article-media__prev', }"
                    :class="`swiper-container article-media__slider`" @swiper="onSwiper" @slideChange="onSlideChange">

                    <SwiperSlide v-for="(item, idx) in media" :key="idx" class="article-media__media-item-wrap">
                        <MediaItem class="article-media__media-item" :item="item" />
                    </SwiperSlide>
                    <button class="swiper-button-prev article-media__prev" v-if="media.length > 3"
                        @click="swiperInstance.slidePrev()"></button>
                    <button class="swiper-button-next article-media__next" v-if="media.length > 3"
                        @click="swiperInstance.slideNext()"></button>
                </swiper>
            </div>
        </div>
    </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import MediaItem from '../MediaItem.vue';
import { ref } from 'vue';

defineProps(['media'])

const swiperInstance = ref()

const onSwiper = (swiper) => {
    swiperInstance.value = swiper
}
</script>

<style lang="scss" scoped>
.article-media {
    overflow: hidden;
    
    &__wrap {
        padding: 50px 0;
        padding-bottom: 0;
    }

    &__heading {
        font-size: 30px;
        margin-bottom: 60px;
    }

    &__media-item-wrap {
        width: calc(100% / 3);

        @media (max-width: 991px) {
            width: calc(100% / 2);
        }

        @media (max-width: 700px) {
            width: 100%;
        }
    }

    &__slider {
        overflow: visible;
    }

    &__prev,
    &__next {
        width: 66px;
        height: 66px;
        bottom: 0;
        top: 0;
        margin: auto;
        background-image: url(@/assets/img/icons/next.svg);
        background-position: center;
        background-size: 66px;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-color: rgba(51, 51, 51, 0.6);

        &::before,
        &::after {
            content: none;
        }

        &:disabled {
            opacity: 0;
        }
    }

    &__prev {
        transform: rotate(180deg);
    }
}
</style>