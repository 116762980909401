<template>

  <div class="login login--reg">
    <RouterLink :to="prevRoute" class="login__close">
        <svg viewBox="0 0 32 32">
          <path stroke="#000" d="M10,10 L22,22 M22,10 L10,22"></path>
        </svg>
      </RouterLink>
    <div class="login__logo">
      <RouterLink to="/">
        <img src="@/assets/img/images/logo-1.svg" alt="Московский центр иновационных технологий в здравоохранении" width="288" height="60">
      </RouterLink>
      <img src="@/assets/img/images/logo-2.svg" alt="Комплекс социального развития Москвы" width="138" height="54">
    </div>
    <template v-if="!registered">
      <p class="login__title">Пожалуйста, заполните поля для регистрации</p>
      <div class="login__wrap">

          <div class="form__fields login__order-2">
            <div class="form__line">
              <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.lastName.$errors.length }">
                <label for="surname" class="form__label">Фамилия*</label>
                <input type="text" v-model="v$.formPayload.lastName.$model" id="surname" class="form__input" required="">
              </div>
              <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.firstName.$errors.length }">
                <label for="name" class="form__label">Имя*</label>
                <input type="text" v-model="v$.formPayload.firstName.$model" id="name" class="form__input" required="">
              </div>
            </div>
            <div class="form__field">
              <label for="patronic" class="form__label">Отчество</label>
              <input type="text" v-model="formPayload.middleName" id="patronic" class="form__input">
            </div>
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.birthdayDate.$errors.length }">
              <label for="date" class="form__label">День, месяц и год рождения*</label>
              <input type="date" v-model="v$.formPayload.birthdayDate.$model" id="date" class="form__input form__input--date">
            </div>
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.email.$errors.length }">
              <label for="email" class="form__label">Электронная почта*</label>
              <input type="email" v-model="v$.formPayload.email.$model" id="email" class="form__input" required="" autocomplete="off">
            </div>
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.phone.$errors.length }">
              <label for="phone" class="form__label">Мобильный телефон*</label>
              <input type="tel" v-model="v$.formPayload.phone.$model" id="phone" class="form__input" required="" v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']">
            </div>
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.snils.$errors.length }">
              <label for="snils" class="form__label">СНИЛС*</label>
              <input type="tel" v-model="v$.formPayload.snils.$model" id="snils" class="form__input" required="" v-maska="['###-###-### ##', '###-###-### ##']" placeholder="___-___-___ __">
            </div>
          </div>
          <div class="form__fields login__order-3">
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.placeOfWork.$errors.length }">
              <label for="work" class="form__label">Место работы*</label>

              <VueMultiselect
                  v-model="v$.formPayload.placeOfWork.$model"
                  :options="medOrgs"
                  placeholder="Выбрать из списка"
                  :select-label="'Выбрать'"
                  :selected-label="'Выбрано'"
                  :deselect-label="``"
                  :showLabels="false"
              >
              </VueMultiselect>

            </div>
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.position.$errors.length }">
              <label for="position" class="form__label">Должность*</label>
              <input type="text" v-model="v$.formPayload.position.$model" id="position" class="form__input" required="">
            </div>
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.education.$errors.length }">
              <label for="education" class="form__label">Образование*</label>
              <VueMultiselect
                  v-model="v$.formPayload.education.$model"
                  :options="profs"
                  placeholder="Выбрать из списка"
                  :select-label="'Выбрать'"
                  :selected-label="'Выбрано'"
                  :deselect-label="``"
                  :showLabels="false"
              >
              </VueMultiselect>
            </div>
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.specialisation.$errors.length }">
              <label for="speciality" class="form__label">Специальность*</label>
              <input type="text" v-model="v$.formPayload.specialisation.$model" id="speciality" class="form__input" required="">
            </div>
            <div class="form__field" >
              <label for="speciality1" class="form__label">Профессиональная переподготовка (специальность) </label>
              <input type="text" v-model="formPayload.speciality" id="speciality1" class="form__input" placeholder="">
            </div>
            <div class="form__field" >
              <label for="speciality2" class="form__label">Наличие действующего сертификата специалиста/аккредитация</label>
              <input type="text" v-model="formPayload.certificate" id="speciality2" class="form__input" placeholder="">
            </div>
          </div>
          <div class="form__fields login__order-4">
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.password.$errors.length }">
              <p class="form__subtitle">Придумайте пароль для учётной записи личного кабинета</p>
              <input type="password" v-model="v$.formPayload.password.$model" class="form__input" placeholder="Придумайте пароль" autocomplete="off">
            </div>
            <div class="form__field" :class="{ 'form__field--error' : v$.formPayload.passwordConfirm.$errors.length }">
              <input type="password" v-model="v$.formPayload.passwordConfirm.$model" class="form__input" placeholder="Повторите ваш пароль" autocomplete="off">
            </div>
          </div>
          <div class="form__fields login__order-5">
            <input type="checkbox" v-model="confirmed" id="checkbox" class="form__checkbox">
            <label for="checkbox" class="form__checkbox-label">Я даю своё <a href="/personalData" data-type="iframe" data-fancybox >согласие на обработку  персональных данных</a></label>
            <p v-if="!confirmed || v$.formPayload.$invalid">Пожалуйста заполните все обязательные поля и дайте согласие на обработку персональных данных</p>
          </div>
          <div v-if="errormsg" class="form__errormsg login__order-7">{{errormsg}}</div>
          <button type="submit" @click.prevent="register" class="btn form__btn login__order-7">Зарегистрироваться</button>
        <div class="login__comment login__comment--top login__order-1">Поля со звёздочкой обязательны к заполению</div>
        <div class="login__comment login__order-6">
          <ul>
            <li>Пароль не может быть короче 8 символов</li>
            <li>В пароле должна быть минимум одна цифра</li>
            <li>В пароле должна быть минимум одна прописная буква</li>
            <li>В пароле должна быть минимум одна строчная буква</li>
          </ul>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="login__ready">
        <p>Данные для регистрации успешно отправлены на верификацию. Дождись обновления статуса</p>
      </div>
      <a href="#" @click.prevent="$router.push('/')" class="btn login__btn" >Завершить и перейти на главную</a>
    </template>

  </div>



</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs, helpers } from '@vuelidate/validators'
import VueMultiselect from 'vue-multiselect'
import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
Fancybox;
export default {
  name: "RegisterView",
  components: { VueMultiselect },
  setup () {
    return { v$: useVuelidate() }
  },
  created() {
    if (this.$store.state.user.authorized) {
      this.$router.push('/');
      return;
    } else {
      this.$store.dispatch('user/logout');
    }
  },
  mounted() {
  },
  data(){
    return {
      registered: false,
      confirmed: false,
      prevRoute: '',
      errormsg: '',
      formPayload: {
        middleName: '', // "Фамилия",
        lastName: '', // "Имя",
        firstName: '', //"Отчество"

        birthdayDate: '', // "2022-03-24T14:14:14",

        email: '', // "testemail@yandex.ru",
        phone: '+7', // "78005553535",
        snils: '', // "78005553535",

        placeOfWork: '', // "Место работы",

        speciality: '', // "Профессиональная переподготовка (специальность) ",
        education: '', // "Образование*",
        specialisation: '', // "Специальность*",
        position: '', // "Должность",
        certificate: '', // "Наличие действующего сертификата специалиста/аккредитация",


        password: '',
        passwordConfirm: '',

      },
      profs: [
        'Высшее образование',
        'Интернатура / ординатура',
        'Иное',
      ],
      medOrgs: ['ГКБ № 1 им. Н.И.Пирогова',
        'ГБУЗ "ГКБ № 4 ДЗМ"',
        'ГБУЗ "ГКБ № 13 ДЗМ"',
        'ГБУЗ "ГКБ № 15 ДЗМ"',
        'ГБУЗ "ГКБ № 17 ДЗМ"',
        'ГБУЗ "ГКБ № 24 ДЗМ"',
        'ГБУЗ "ГКБ № 29 им. Н.Э. Баумана"',
        'ГБУЗ "ГКБ № 31 ДЗМ"',
        'ГБУЗ "ММКЦ "Коммунарка" ДЗМ"',
        'ГБУЗ "ГКБ № 51 ДЗМ"',
        'ГБУЗ "ГКБ № 52 ДЗМ"',
        'ГКБ № 67',
        'ГБУЗ "ГКБ им. братьев Бахрушиных ДЗМ"',
        'ГБУЗ ГКБ им. С.П. Боткина ДЗМ',
        'ГБУЗ "ГКБ имени В.М. Буянова ДЗМ"',
        'ГБУЗ "ГКБ им. В.В. Вересаева ДЗМ"',
        'ГБУЗ "ГКБ им. В.В. Виноградова ДЗМ"',
        'ГБУЗ "Вороновская больница ДЗМ"',
        'ГБУЗ "ГКБ им. И.В. Давыдовского ДЗМ"',
        'ГБУЗ "ГКБ имени В.П. Демихова ДЗМ"',
        'ГБУЗ "ГКБ им. А.К. Ерамишанцева ДЗМ"',
        'ГБУЗ "ГКБ им. М.Е. Жадкевича ДЗМ"',
        'ГБУЗ "ГКБ им. Ф.И. Иноземцева ДЗМ"',
        'ГБУЗ "ГКБ им. М.П. Кончаловского ДЗМ"',
        'ГБУЗ "Больница "Кузнечики" ДЗМ"',
        'ГБУЗ "ГКБ им. Е.О. Мухина ДЗМ"',
        'ГБУЗ "ГКБ им. Д.Д. Плетнёва ДЗМ"',
        'ГБУЗ НИКИО им. Л.И. Свержевского ДЗМ',
        'ГБУЗ "ГКБ им. С.И. Спасокукоцкого ДЗМ"',
        'ГБУЗ "ГКБ им. С.С. Юдина ДЗМ"',
        'НИИ НДХиТ',
        'ГБУЗ "НИИ СП им. Н.В. Склифосовского ДЗМ"',
        'ГБУЗ "ТГБ ДЗМ"',
        'ГБУЗ "ЩГБ ДЗМ"',
        'ГБУЗ "Эндокринологический диспансер ДЗМ"',
        'ГБУЗ "Центр паллиативной помощи ДЗМ"',
        'ГБУЗ "Городская больница г.Московский ДЗМ"',
        'ГБУЗ "ГВВ № 1 ДЗМ"',
        'ГБУЗ "ГВВ № 2 ДЗМ"',
        'ГБУЗ "ГВВ № 3 ДЗМ"',
        'ГБУЗ "ИКБ № 1 ДЗМ"',
        'ГБУЗ ИКБ № 2 ДЗМ',
        'ГБУЗ "ТБ им. А.Е.Рабухина ДЗМ"',
        'ГБУЗ "ТКБ № 3 ДЗМ"',
        'ГБУЗ "ДГКБ им.З.А. Башляевой ДЗМ"',
        'ГБУЗ "Морозовская ДГКБ ДЗМ"',
        'ГБУЗ "ДГКБ св. Владимира ДЗМ"',
        'ГБУЗ "ДГКБ им. Н.Ф. Филатова ДЗМ"',
        'ГБУЗ "ДИКБ № 6 ДЗМ"',
        'ГБУЗ "ДГКБ № 9 им. Г.Н. Сперанского ДЗМ"',
        'ГБУЗ "ГКОБ № 1 ДЗМ"',
        'ГБУЗ "МГОБ № 62 ДЗМ"',
        'ГБУЗ "ПКБ № 1 ДЗМ"',
        'ГБУЗ "ПКБ № 4 ДЗМ"',
        'ГБУЗ "ПКБ № 5 ДЗМ"',
        'ГБУЗ "ПКБ № 13 ДЗМ"',
        'ГБУЗ "ПНД № 22 ДЗМ"',
        'ГАУЗ МНПЦ МРВСМ ДЗМ',
        'ГАУЗ "ЦВРТ ДЗМ"',
        'ГБУЗ "МНПЦ борьбы с туберкулезом ДЗМ"',
        'ГБУЗ "МГЦРБ ДЗМ"',
        'ГБУЗ МКНЦ имени А.С. Логинова ДЗМ',
        'ГБУЗ "Московский Центр дерматовенерологии и косметологии"',
        'ГБУЗ "МНПЦ наркологии ДЗМ"',
        'ГБУЗ НПЦ им. Соловьева ДЗМ',
        'ГБУЗ "НПЦ ДП ДЗМ"',
        'ГБУЗ "НПЦ ПЗДП им. Г.Е. Сухаревой ДЗМ"',
        'ГБУЗ "НПЦ спец.мед.помощи детям ДЗМ"',
        'ГБУЗ "ЦМП ДЗМ"',
        'ГБУЗ ЦПСиР ДЗМ',
        'ГБУЗ "ЧЛГ для ВВ ДЗМ"',
        'ГБУЗ особого типа "МТНПЦМК (ЦЭМП) ДЗМ"',
        'ГБУЗ "ДКЦ № 1 ДЗМ"',
        'ГБУЗ "КДЦ № 2 ДЗМ"',
        'ГБУЗ "ДЦ № 3 ДЗМ"',
        'ГБУЗ "КДЦ № 4 ДЗМ"',
        'ГБУЗ "ДЦ № 5 ДЗМ"',
        'ГБУЗ "КДЦ № 6 ДЗМ"',
        'ГБУЗ "ДЦЛИ ДЗМ"',
        'ГБУЗ "КДП № 121 ДЗМ"',
        'ГБУЗ "ГП № 2 ДЗМ"',
        'ГБУЗ "ГП № 3 ДЗМ"',
        'ГБУЗ "ГП № 5 ДЗМ"',
        'ГБУЗ "ГП № 6 ДЗМ"',
        'ГБУЗ "ГП № 8 ДЗМ"',
        'ГБУЗ "ГП № 9 ДЗМ"',
        'ГБУЗ "ГП № 11 ДЗМ"',
        'ГБУЗ "ГП № 12 ДЗМ"',
        'ГБУЗ "ГП № 19 ДЗМ"',
        'ГБУЗ "ГП № 22 ДЗМ"',
        'ГБУЗ "ГП № 23 ДЗМ"',
        'ГБУЗ "ГП № 36 ДЗМ"',
        'ГБУЗ "ГП № 45 ДЗМ"',
        'ГБУЗ "ГП № 46 ДЗМ"',
        'ГБУЗ "ГП № 52 ДЗМ"',
        'ГБУЗ "ГП № 62 ДЗМ"',
        'ГБУЗ "ГП № 64 ДЗМ"',
        'ГБУЗ "ГП № 66 ДЗМ"',
        'ГБУЗ "ГП № 67 ДЗМ"',
        'ГБУЗ "ГП № 68 ДЗМ"',
        'ГБУЗ "ГП № 69 ДЗМ"',
        'ГБУЗ "ГП № 107 ДЗМ"',
        'ГБУЗ "ГП № 109 ДЗМ"',
        'ГБУЗ "ГП № 115 ДЗМ"',
        'ГБУЗ "ГП № 134 ДЗМ"',
        'ГБУЗ "ГП № 166 ДЗМ"',
        'ГБУЗ г. Москвы ГП № 170 ДЗМ',
        'ГБУЗ "ГП № 175 ДЗМ"',
        'ГБУ ГП № 180 ДЗМ',
        'ГБУЗ "ГП № 191 ДЗМ"',
        'ГБУЗ "ГП № 195 ДЗМ"',
        'ГБУЗ "ГП № 209 ДЗМ"',
        'ГБУЗ "ГП № 210 ДЗМ"',
        'ГБУЗ "ГП № 212 ДЗМ"',
        'ГБУЗ "ГП № 214 ДЗМ"',
        'ГБУЗ "ГП № 218 ДЗМ"',
        'ГБУЗ "ГП № 219 ДЗМ"',
        'ГБУЗ "ГП № 220 ДЗМ"',
        'ГБУЗ "ДГП № 7 ДЗМ"',
        'ГБУЗ "ДГП № 10 ДЗМ"',
        'ГБУЗ "ДГП № 11 ДЗМ"',
        'ГБУЗ "ДГП № 12 ДЗМ"',
        'ГБУЗ "ДГП № 15 ДЗМ"',
        'ГБУЗ "ДГП № 23 ДЗМ"',
        'ГБУЗ "ДГП № 28 ДЗМ"',
        'ГБУЗ "ДГП № 30 ДЗМ"',
        'ГБУЗ "ДГП № 32 ДЗМ"',
        'ГБУЗ "ДГП № 38 ДЗМ"',
        'ГБУЗ "ДГП № 39 ДЗМ"',
        'ГБУЗ "ДГП № 42 ДЗМ"',
        'ГБУЗ "ДГП № 48 ДЗМ"',
        'ГБУЗ "ДГП № 52 ДЗМ"',
        'ГБУЗ "ДГП № 58 ДЗМ"',
        'ГБУЗ "ДГП № 61 ДЗМ"',
        'ГБУЗ "ДГП № 69 ДЗМ"',
        'ГБУЗ "ДГП № 81 ДЗМ"',
        'ГБУЗ "ДГП № 86 ДЗМ"',
        'ГБУЗ "ДГП № 91 ДЗМ"',
        'ГБУЗ "ДГП № 94 ДЗМ"',
        'ГБУЗ "ДГП № 98 ДЗМ"',
        'ГБУЗ "ДГП № 99 ДЗМ"',
        'ГБУЗ "ДГП № 104 ДЗМ"',
        'ГБУЗ "ДГП № 105 ДЗМ"',
        'ГБУЗ "ДГП № 110 ДЗМ"',
        'ГБУЗ "ДГП № 118 ДЗМ"',
        'ГБУЗ "ДГП № 120 ДЗМ"',
        'ГБУЗ "ДГП № 122 ДЗМ"',
        'ГБУЗ "ДГП № 125 ДЗМ"',
        'ГБУЗ "ДГП № 129 ДЗМ"',
        'ГБУЗ "ДГП № 130 ДЗМ"',
        'ГБУЗ "ДГП № 131 ДЗМ"',
        'ГБУЗ "ДГП № 132 ДЗМ"',
        'ГБУЗ "ДГП № 133 ДЗМ"',
        'ГБУЗ "ДГП № 140 ДЗМ"',
        'ГБУЗ "ДГП № 143 ДЗМ"',
        'ГБУЗ "ДГП № 145 ДЗМ"',
        'ГБУЗ "ДГП № 148 ДЗМ"',
        'ГБУЗ "ДГП № 150 ДЗМ"',
        'ГАУЗ "СП № 2 ДЗМ"',
        'ГАУЗ "СП № 3 ДЗМ"',
        'ГАУЗ "СП № 4 ДЗМ"',
        'ГАУЗ "СП № 5 ДЗМ"',
        'ГАУЗ "СП № 7 ДЗМ"',
        'ГАУЗ "СП № 8 ДЗМ"',
        'ГАУЗ "СП № 9 ДЗМ"',
        'ГАУЗ "СП № 11 ДЗМ"',
        'ГАУЗ "СП № 12 ДЗМ"',
        'ГАУЗ "СП № 13 ДЗМ"',
        'ГАУЗ "СП № 14 ДЗМ"',
        'ГАУЗ "СП № 15 ДЗМ"',
        'ГАУЗ "СП № 19 ДЗМ"',
        'ГАУЗ "СП № 22 ДЗМ"',
        'ГАУЗ "СП № 23 ДЗМ"',
        'ГАУЗ "СП № 24 ДЗМ"',
        'ГАУЗ "СП № 27 ДЗМ"',
        'ГАУЗ "СП № 32 ДЗМ"',
        'ГАУЗ "СП № 33 ДЗМ"',
        'ГАУЗ "СП № 34 ДЗМ"',
        'ГАУЗ "СП № 35 ДЗМ"',
        'ГАУЗ "СП № 48 ДЗМ"',
        'ГАУЗ "СП № 49 ДЗМ"',
        'ГАУЗ "СП № 50 ДЗМ"',
        'ГАУЗ "СП № 51 ДЗМ"',
        'ГАУЗ "СП № 53 ДЗМ"',
        'ГАУЗ "СП № 56 ДЗМ"',
        'ГАУЗ "СП № 60 ДЗМ"',
        'ГАУЗ "СП № 61 ДЗМ"',
        'ГАУЗ "СП № 62 ДЗМ"',
        'ГАУЗ "СП № 64 ДЗМ"',
        'ГАУЗ "СП № 65 ДЗМ"',
        'ГАУЗ "СП № 66 ДЗМ"',
        'ГАУЗ "СП № 67 ДЗМ"',
        'ГБУЗ "ДСП № 1 ДЗМ"',
        'ГБУЗ "ДСП № 6 ДЗМ"',
        'ГБУЗ "ДСП № 10 ДЗМ"',
        'ГБУЗ "ДСП № 16 ДЗМ"',
        'ГБУЗ "ДСП № 21 ДЗМ"',
        'ГБУЗ "ДСП № 25 ДЗМ"',
        'ГБУЗ "ДСП № 26 ДЗМ"',
        'ГБУЗ "ДСП № 28 ДЗМ"',
        'ГБУЗ "ДСП № 29 ДЗМ"',
        'ГБУЗ "ДСП № 30 ДЗМ"',
        'ГБУЗ "ДСП № 37 ДЗМ"',
        'ГБУЗ "ДСП № 38 ДЗМ"',
        'ГБУЗ "ДСП № 39 ДЗМ"',
        'ГБУЗ "ДСП № 41 ДЗМ"',
        'ГБУЗ "ДСП № 43 ДЗМ"',
        'ГБУЗ "ДСП № 44 ДЗМ"',
        'ГБУЗ "ДСП № 45 ДЗМ"',
        'ГБУЗ "ДСП № 46 ДЗМ"',
        'ГБУЗ "ДСП № 47 ДЗМ"',
        'ГБУЗ "ДСП № 52 ДЗМ"',
        'ГБУЗ "ДСП № 54 ДЗМ"',
        'ГБУЗ "ДСП № 58 ДЗМ"',
        'ГБУЗ "ДСП № 59 ДЗМ"',
        'ГБУЗ "ДСП № 63 ДЗМ"',
        'ГБУЗ "НПКЦ ДиТ ДЗМЦ"',
        'ГБУЗ г.Москвы «Центр патологии речи и нейрореабилитации Департамента здравоохранения города Москвы»,'
      ],
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from !== '/register') {
        vm.prevRoute = from.fullPath
        return
      }

      vm.prevRoute = '/'
    })
  },
  validations() {
    return {
      formPayload: {
        lastName: {
          required
        },
        firstName: {
          required
        },
        birthdayDate: {
          required
        },
        email: {
          required, email
        },
        phone: {
          required
        },
        placeOfWork: {
          required
        },
        education: {
          required
        },
        specialisation: {
          required
        },
        position: {
          required
        },
        snils: {
          required
        },
        password: {
          required,
          min: minLength(8),
          containsPasswordRequirement: helpers.withMessage(
              () => `The password requires an uppercase, lowercase, number and special character`,
              (v) => /^(?=.*\d)(?=.*[a-zа-я])(?=.*[A-ZА-Я])[0-9a-zA-Zа-яА-Я!@#$%^&*()-]{8,}$/.test(v)
          ),
        },
        passwordConfirm: {
          required,
          min: minLength(8),
          sameAs: sameAs(this.formPayload.password)
        },
      },
    }
  },
  methods: {
    async register(){
      if (!this.confirmed || this.v$.formPayload.$invalid) {
        this.v$.$touch();
        return;
      }
      const payload = this.formPayload;
      const result = await this.$store.dispatch('user/register', payload);
      if (result.success) {
        this.registered = true;
      }
      if (!result.success && result.message) {
        this.errormsg = result.message;
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>

.form__errormsg {
  color: red;
  text-align: center;
}

.multiselect__tags {
  background: transparent!important;
}

.multiselect--active .multiselect__tags {
  background: #fff!important;
}

.multiselect__input, .multiselect__single {
  background: transparent;
}

.multiselect__option--highlight {
  background:  #E0E0E0;
}
.multiselect__option--selected.multiselect__option--highlight {
  background:  #4775e3;
}

.multiselect__option {
  white-space: normal!important;
}

.multiselect__option:hover::after,
.multiselect__option--highlight:hover::after {
  display: none!important;
}

</style>

<style lang="scss" scoped>
.login {
  position: relative;

  &__close {
    position: absolute;
    top: 40px;
    right: -200px;
    width: 60px;
    height: 60px;

    @media (max-width: 768px) {
      top: 100px;
      right: 10px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>