import axios from "axios";

axios.defaults.baseURL = 'https://api.directual.com/good/api/v5/data/';
const appId = '14411970-78ad-47b2-a55d-af4851f9ada2';

export default {
  namespaced: true,
  state: {
    themes: [],
  },
  getters: {
    getThemes(state){
      return state.themes;
    },
    getThemesFromCacheById: () => (id) => {
      const cache = JSON.parse(getFromLocalStorage('surveyThemes'));
      return cache.find(e => e.id === id);
    },

  },
  mutations: {
  },
  actions: {
    async fetchThemes({state, rootGetters}) {
      if (state.themes && state.themes.length) return;
      const token = rootGetters["user/getAuthToken"];
      const response = await axios.get(`/educationSurveyTheme/getEducationSurveyTheme?appID=${appId}&sessionID=${token}`);

      if (response.data && response.data.payload) {
        state.themes = response.data.payload;
        saveToLocalStorage('surveyThemes', response.data.payload);
      }
    },
    async sendEduRequest({rootGetters}, payload){
        try {
          const token = rootGetters["user/getAuthToken"];
          const response = await axios.post(`/educationSurvey/editEducationSurvey?appID=${appId}&sessionID=${token}`, payload);
          if (response.data && response.data.result[0]) {
            return {success: true};
          }
          return {success: false};
        } catch (e){
          console.log(e);
        }
      },
  },
}

function getFromLocalStorage(key){
  return localStorage.getItem(key);
}
function saveToLocalStorage(key, data){
  if (!key || !data) return;
  localStorage.setItem(key, JSON.stringify(data));
}