<template>
    <section class="platform platform--top">
        <div class="wrapper">
            <div class="anounces" id="announces">
                <div class="media__wrap">
                    <p class="title">Мероприятия и активности Департамента КИ</p>
                    <swiper :slides-per-view="'auto'" :space-between="20"
                        :navigation="{ nextEl: '.media__next', prevEl: '.media__prev', }"
                        :class="`swiper-container media__slider`" @swiper="onSwiper" @slideChange="onSlideChange"
                        v-if="preparedAnnounces.length">

                        <swiper-slide v-for="(item, idx) in preparedAnnounces" :key="idx" class="media__slide">
                            <MediaItem class="article-media__media-item" :item="item" />
                        </swiper-slide>

                        <div class="swiper-button-prev event__prev media__prev"
                            :class="swiper?.realIndex === 0 && 'media__prev--disabled'" @click="swiper.slidePrev()"></div>
                        <div class="swiper-button-next event__next media__next"
                            :class="swiper?.realIndex + 3 == swiper?.slides.length && 'media__next--disabled'"
                            @click="swiper.slideNext()" v-if="preparedAnnounces.length > 1">
                        </div>
                    </swiper>

                    <div v-else class="event__banner event__banner--back">
                        <p>Скоро здесь появятся анонсы событий, следите за обновлениями</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
//import { useSwiper } from 'swiper/vue';
import moment from "moment-with-locales-es6";
import 'swiper/css';
import 'swiper/css/navigation';
import MediaItem from '../MediaItem.vue';

export default {
    name: "sopList",
    components: {
        Swiper,
        SwiperSlide,
        MediaItem
        //useSwiper
    },
    setup() {
        const onSlideChange = () => { };
        return { onSlideChange, };
    },
    computed: {
        userIsAllowed() {
            const $store = this.$store;
            return ($store.state.user.authorized
                && $store.state.user.userFields?.isVerified
                && ($store.state.user.userFields?.status === 'Верифицирован из списка'
                    || $store.state.user.userFields?.status === 'Верифицирован')
            )
        },
        preparedAnnounces() {
            let result = this.$store.state.materials.announces;
            return result.sort((a, b) => b.createDate - a.createDate);
        },
        userIsAuthorized() {
            return this.$store.state.user.authorized || false
        },
        userIsVerified() {
            return this.$store.state.user.userFields.isVerified || false
        },
    },
    data() {
        return {
            swiper: null,
            signedIds: []
        };
    },
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        getFormattedDate(date) {
            const momentDate = moment(date).clone();

            return momentDate.fromNow()

        }
    }
}
</script>

<style lang="scss" scoped>
.media {
    &__wrap {
        position: relative;
        margin-bottom: 140px;
    }

    &__slide,
    &__slider {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: visible;
    }

    &__slide {
        width: 360px;
        color: #fff;
        border-radius: 20px;
        overflow: hidden;
        -webkit-transition: ease-out 0.3s;
        transition: ease-out 0.3s;
        filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.05));
    }

    &__item {
        text-decoration: none;
        padding: 20px;
        display: flex;
        flex-direction: column;
        height: 420px;
        z-index: 10;
        position: relative;
    }

    &__img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &__tag {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid #FFFFFF;
        border-radius: 40px;
        padding: 8px 20px;
        width: fit-content;
        font-size: 14px;
        margin: 0 auto;
        margin-bottom: auto;
    }

    &__date {
        opacity: 0.4;
        margin-bottom: 5px;
    }

    &__title {
        font-size: 20px;
        text-align: left;
        margin-bottom: 6px;
        margin-top: 0;
    }

    &__subtitle {
        font-size: 16px;
    }

    &__prev,
    &__next {
        width: 66px;
        height: 66px;
        bottom: 0;
        top: 0;
        margin: auto;
        background-image: url(@/assets/img/icons/next.svg);
        background-position: center;
        background-size: 66px;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-color: rgba(51, 51, 51, 0.6);
        transition: 500ms;

        &--disabled {
            opacity: 0;
            transition: 500ms;
        }

        &::before,
        &::after {
            content: none;
        }
    }

    &__prev {
        left: -15vw;
        transform: rotate(180deg);

        @media (max-width: 1700px) {
            left: -10vw;
        }

        @media (max-width: 1500px) {
            left: 0;
        }
    }

    &__next {
        right: -15vw;

        @media (max-width: 1700px) {
            right: -10vw;
        }

        @media (max-width: 1500px) {
            right: 0;
        }
    }

    &__link {
        background: #1274E0;
        border-radius: 50px;
        width: 100%;
        max-width: 195px;
        padding: 12px 24px;
        height: auto;
        display: flex;
        color: #fff;
        justify-content: center;
        text-align: center;
        margin: auto;
        margin-top: 60px;
        font-size: 16px;
        text-decoration: none;
    }
}
</style>