<template>
    <div class="tabs">
        <ul class="tabs__list">
            <li class="tabs__item" v-if="hasSearch">
                <SearchForm class="tabs__search-form" v-model:value="query" placeholder="Поиск по тегам" />
            </li>
            <li class="tabs__item" v-for="item in tabsToShow" :key="item">
                <button class="tabs__btn" :class="activeTab === item && 'tabs__btn--active'" @click="$emit('update:activeTab', item)">
                    {{ item }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import SearchForm from './SearchForm.vue';

const props = defineProps(['tabs', 'activeTab', 'hasSearch'])

defineEmits(['update:activeTab'])

const query = ref('')

const tabsToShow = computed(() => {
    if (!query.value.length && props.hasSearch) return props.tabs.slice(0, 10)

    if (!props.hasSearch) return props.tabs

    return props.tabs.filter((el) => {
      if (!query.value.length || query.value === 'все') return true;
      return (
        el.toLowerCase().includes(query.value.toLowerCase())
      );
    });
})
</script>

<style lang="scss" scoped>
.tabs {
    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        white-space: nowrap;
        overflow: auto;
        gap: 10px;

        @media (max-width: 991px) {
            margin: 0 -20px;
            padding: 0 20px;
            padding-bottom: 16px;
        }
    }

    &__search-form {
        max-width: 240px;
    }

    &__btn {
        border: 1px solid #333333;
        border-radius: 40px;
        font-size: 20px;
        padding: 10px 20px;
        transition: 350ms;

        &--active {
            background-color: #333;
            color: #fff;
            transition: 350ms;
        }
    }
}
</style>