<template>
  <base-layout>
    <router-view />
  </base-layout>
  <vue-progress-bar></vue-progress-bar>
</template>

<script>
import BaseLayout from "@/components/layouts/BaseLayout";
export default {
  components: { BaseLayout },
  mounted() {
    this.$Progress.finish();
  },
  watch: {
    $route() {
      document.documentElement.style.overflow = 'auto'
    }
  },
  created() {
    this.$store.dispatch('user/initCache');

    this.$store.dispatch('user/fetchUserFields');
    this.$store.dispatch('events/fetchEvents');
    this.$store.dispatch('events/fetchGcpEvents');
    this.$store.dispatch('events/fetchPresentations');
    this.$store.dispatch('materials/fetchSop');
    this.$store.dispatch('materials/fetchInstructions');
    this.$store.dispatch('materials/fetchMedia');
    this.$store.dispatch('materials/fetchAnnounces');
    this.$store.dispatch('educationSurvey/fetchThemes')

    this.$Progress.start();

    this.$router.beforeEach(() => {
      this.$Progress.start();
    });

    this.$router.afterEach(() => {
      if (!this.$route.hash) {
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        })
      }

      this.$Progress.finish();

      if (this.$route.hash) {
        setTimeout(() => {
          const target = document.querySelector(`${this.$route.hash}`);

          if (!target) return

          this.$smoothScroll({
            offset: -70,
            scrollTo: target,
            hash: this.$route.hash
          })
        }, 300)
      }
    });
  },
}
</script>