<template>
    <div class="event-person">
        <div class="event-person__type">{{ type }}</div>

        <div class="event-person__content">
            <div class="event-person__img" v-if="photo">
                <img :src="photo.urlLink" alt="">
            </div>

            <div class="event-person__info">
                <div class="event-person__name">
                    {{ name }}
                </div>

                <div class="event-person__job">
                    {{ job }}
                </div>

                <div ref="eventPersonLink">

                    <button v-if="person" class="event-person__link" @click.stop="showHint = true"
                        @mouseenter="showHint = true" @mouseleave="showHint = !isMobile ? false : true">
                        Подробнее..
                    </button>

                    <Hint :show="showHint" v-if="person">
                        <div class="event-person__popup">
                            <div class="event-person__popup-text"  v-if="person.shortDescription" v-html="person.shortDescription"></div>

                            <div class="event-person__popup-text" v-if="person.longDescription" v-html="person.longDescription"></div>
                        </div>
                    </Hint>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import Hint from "./Hint.vue";

const props = defineProps(['type', 'job', 'photo', 'name', 'person'])

const isMobile = computed(() => {
    return window.innerWidth < 768
})

console.log(props.person)

const showHint = ref(false)
const eventPersonLink = ref(null)

const clickHandler = (e) => {
    if (eventPersonLink.value.contains(e.target)) return

    showHint.value = false
}

onMounted(() => {
    document.addEventListener('click', clickHandler)
})

onUnmounted(() => {
    document.removeEventListener('click', clickHandler)
})
</script>

<style lang="scss" scoped>
.event-person {
    &__type {
        margin-bottom: 12px;
        padding: 4px 20px;
        border-radius: 50px;
        border: 1px solid #000;
        font-weight: 600;
        width: fit-content;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }

    &__img {
        width: 100%;
        max-width: 160px;
        height: 200px;
        border-radius: 30px;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &__name {
        font-weight: 600;
        margin-bottom: 8px;
    }

    &__job {
        font-size: 14px;
        line-height: 140%;
        color: #0e0e0e;
        margin-bottom: 20px;
    }

    &__link {
        color: #2194FF;
        font-size: 14px;
        display: block;
    }

    &__popup {
        font-size: 14px;
        line-height: 140%;
        color: #0e0e0e;

        &-title {
            font-weight: 700;
        }

        &-text {
            margin-bottom: 8px;
        }
    }
}
</style>