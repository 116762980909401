<template>
  <footer v-if="!$route.meta.hideFooter" id="footerSection" class="footer">
    <div class="wrapper">
      <div class="footer__wrap">
        <span>(с) {{ year }}, московский центр инновационных технологий в здравоохранении</span>
        <a href="/policy" data-type="iframe" data-fancybox>политика конфиденциальности</a>
      </div>
    </div>
  </footer>
</template>

<script>

import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
Fancybox;

export default {
  name: "FooterLayout",
  computed: {
    year() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>