<template>
  <div class="photo-page" id="media" data-nav-section>
    <div class="photo-page__wrap">
      <div class="wrapper">
        <nav class="photo-page__breadcrumbs">
          <router-link to="/#media" class="breadcrumbs__link"
            >Медиа</router-link
          >
          <span class="breadcrumbs__link">{{ currentArticle?.name }}</span>
        </nav>

        <div class="photo-page__content">
          <div class="photo-page__date" v-if="currentArticle.createDate">
            {{ getFormattedDate(currentArticle.createDate) }}
          </div>

          <ul class="photo-page__list" ref="gallery">
            <li
              class="photo-page__item"
              v-for="photo in currentArticle.educationMediaPhoto_ids"
              :key="photo.id"
            >
              <a
                data-pswp-width="1200"
                data-pswp-height="600"
                :href="photo.file_id.urlLink"
                class="photo-page__item-link"
              >
                <figure>
                  <img :src="photo.file_id.urlLink" :alt="photo.description" />
                  <figcaption
                    v-if="photo.description"
                    v-html="photo.description"
                  ></figcaption>
                </figure>
              </a>
            </li>
          </ul>

          <div class="photo-page__text" v-if="currentArticle.description" v-html="currentArticle.description">
          </div>
        </div>
      </div>
    </div>

    <nav class="photo-page__breadcrumbs photo-page__breadcrumbs--bottom">
      <div class="wrapper">
        <router-link to="/#media" class="breadcrumbs__link">Медиа</router-link>
        <span class="breadcrumbs__link">{{ currentArticle?.name }}</span>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

import moment from 'moment-with-locales-es6';

const store = useStore();
const route = useRoute();
const router = useRouter();
const { id } = route.params;
const lightbox = ref();
const gallery = ref();

const initLightbox = () => {
  lightbox.value = new PhotoSwipeLightbox({
    gallery: gallery.value,
    bgOpacity: 1,
    children: 'a',
    showHideAnimationType: 'zoom',
    pswpModule: () => import('photoswipe'),
    wheelToZoom: true,
    counter: false,
  });

  lightbox.value.on('uiRegister', function () {
    lightbox.value.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: 'Caption text',
      onInit: (el) => {
        lightbox.value.pswp.on('change', () => {
          const currentItem = lightbox.value.pswp.currSlide.data.element;
          if (currentItem) {
            const caption = currentItem.querySelector('figcaption');

            if (caption) {
              el.innerHTML = caption.innerHTML;
            }
          }
        });
      },
    });
  });

  lightbox.value.init();
};

const currentArticle = computed(() => {
  let result = false;

  result = store.getters['materials/getMediaFromCacheById'](id);

  return result;
});

const getFormattedDate = (date) => {
  moment.locale('ru');
  const momentDate = moment(date).clone();

  return momentDate.format('D MMMM YYYY года');
};

if (!currentArticle.value) {
  router.push('/');
}

console.log(currentArticle.value);

onMounted(() => {
  initLightbox();
});

onUnmounted(() => {
  if (lightbox.value) {
    lightbox.value.destroy();
    lightbox.value = null;
  }
});
</script>

<style>
.pswp img {
  object-fit: contain;
  object-position: center;
  margin: auto;
}

.pswp__custom-caption {
  background: rgba(255, 255, 255, 0.75);
  font-size: 16px;
  text-align: center;
  color: #000;
  width: 100%;
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  width: fit-content;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 16px;
  transform: translateX(-50%);
}
.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}
</style>

<style lang="scss" scoped>
.photo-page {
  &__wrap {
    padding: 100px 0;

    @media (max-width: 600px) {
      padding: 40px 0;
    }
  }

  &__img {
    max-height: 60vh;
    margin: auto;
    display: block;
    margin-bottom: 20px;
  }

  &__text {
    margin-bottom: 20px;
  }

  &__breadcrumbs {
    margin-bottom: 60px;

    &--bottom {
      padding: 20px 0;
      border-top: 1px solid rgba(#16171e, 0.2);
      border-bottom: 1px solid rgba(#16171e, 0.2);
      margin-bottom: 0;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 32px;
    columns: 3;

    @media (max-width: 991px) {
        columns: 2;
    }

    @media (max-width: 600px) {
        columns: 1;
    }
  }

  &__item a {
    text-decoration: none;
  }
}
</style>
