<template>
    <div class="material__wrap">
        <ul class="material__list">
            <li class="material__list-item" v-for="item in instructions.filter(el => el.name === 'Нормативные документы')"
                :key="item.id">
                <RouterLink :to="`instruction/${item.id}`" class="material__item-link material__item-link--accent">
                    <div class="material__item-title">{{ item.name }}</div>

                    <div class="material__item-count" v-if="getTotalDocumentsCount(item.documents)">{{ getTotalDocumentsCount(item.documents) }}
                        {{ plural(getTotalDocumentsCount(item.documents), 'материал', 'материала', 'материалов') }}</div>
                </RouterLink>
            </li>
            <li class="material__list-item" v-for="item in instructions.filter(el => el.name != 'Нормативные документы')" :key="item.id">
                <RouterLink :to="`instruction/${item.id}`" class="material__item-link">
                    <div class="material__item-title">{{ item.name }}</div>

                    <div class="material__item-count" v-if="getTotalDocumentsCount(item.documents)">{{ getTotalDocumentsCount(item.documents) }}
                        {{ plural(getTotalDocumentsCount(item.documents), 'материал', 'материала', 'материалов') }}</div>
                </RouterLink>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import plural from 'plural-ru'
import { computed } from 'vue'

const store = useStore()

const instructions = computed(() => {
    let result = false

    result = store.getters['materials/getInstructions']
    return result.sort()
})

const getTotalDocumentsCount = (documents) => {
    return documents.reduce((acc, el) => acc + el.publications?.length + el.materials.length, 0)
}
</script>

<style lang="scss" scoped>
.material {
    &__list {
        list-style: none;
        padding: 0;
        display: grid;
        gap: 30px;
        margin-bottom: 100px;
    }

    &__item {
        &-link {
            display: flex;
            gap: 28px;
            align-items: center;
            padding: 40px 30px;
            padding-right: 100px;
            background: #fff;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 15L8 8L1 1' stroke='%23333333' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: calc(100% - 50px);
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
            border-radius: 30px;
            text-decoration: none;
            height: 100%;
            transition: 350ms;

            @media (max-width: 768px) {
                padding: 16px;
                padding-right: 50px;
                background-position: calc(100% - 16px);
                border-radius: 15px;
            }

            @media (max-width: 568px) {
                flex-direction: column;
                padding-right: 16px;
                background-position-y: 30px;
            }

            &:hover {
                box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
                transform: scale(1.01);
                transition: 350ms;
            }

            &--accent {
                background-color: #1274E0;
                color: #fff;
                background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 15L8 8L1 1' stroke='%23fff' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");


                .material__item-count {
                    border-color: #fff;
                }
            }
        }

        &-title {
            max-width: 600px;
            font-size: 20px;

            @media (max-width: 768px) {
                font-size: 16px;
            }

            @media (max-width: 568px) {
                padding-right: 32px;
            }
        }

        &-count {
            border: 1px solid #000000;
            border-radius: 40px;
            font-size: 14px;
            padding: 8px 20px;
            margin-left: auto;
            width: 50%;
            max-width: 160px;
            text-align: center;

            @media (max-width: 768px) {
                font-size: 12px;
            }

            @media (max-width: 568px) {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}</style>