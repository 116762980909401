<template>
    <div class="contacts">
        <div class="contacts__wrap">
            <ul class="contacts__list">
                <li class="contacts__item">
                    <div class="contacts__item-title">Карева Мария Анатольевна</div>
                    <div class="contacts__item-job">Директор Департамента клинических исследований Московского центра инновационных технологий в здравоохранении (Медтех)</div>
                    <a href="tel:+79852205632" class="contacts__item-link">8 (985) 220-56-32</a>
                    <a class="contacts__item-link" target="_blank"
                        href="mailto:KarevaMA3@zdrav.mos.ru">KarevaMA3@zdrav.mos.ru</a>
                </li>

                <li class="contacts__item">
                    <div class="contacts__item-title">Зырянов Сергей Кенсаринович</div>
                    <div class="contacts__item-job">Главный внештатный специалист по клиническим исследованиям ДЗМ</div>
                    <a class="contacts__item-link" target="_blank"
                        href="mailto:ZyryanovSK@zdrav.mos.ru">ZyryanovSK@zdrav.mos.ru</a>
                </li>

                <li class="contacts__item">
                    <div class="contacts__item-title">Департамент Клинических исследований:</div>
                    <a href="tel:+79152702939" class="contacts__item-link">8 (915) 270-29-39</a>
                    <a class="contacts__item-link" target="_blank"
                        href="mailto:DCR-info@zdrav.mos.ru ">DCR-info@zdrav.mos.ru </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.contacts {
    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;

        @media (max-width: 991px) {
            grid-template-columns:  1fr 1fr;
        }

        @media (max-width: 680px) {
            grid-template-columns:  1fr;
        }
    }

    &__item-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
        border-bottom: 2px solid #1274E0;
        width: fit-content;
    }

    &__item-link {
        color: #1274E0;
        display: block;
        margin-bottom: 8px;
    }

    &__item-job {
        margin-bottom: 10px;
    }
}
</style>