<template>
    <div class="hint" v-if="show" ref="reference">
        <div class="hint__wrap" ref="floating" :style="floatingStyles">
            <slot />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useFloating, autoUpdate, offset, shift } from '@floating-ui/vue';

defineProps(['show'])

const floating = ref(null);
const reference = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
    whileElementsMounted: autoUpdate,
    placement: 'right-start',
    middleware: [
        offset({
            mainAxis: -20,
            crossAxis: -20
        }),
        shift({
            crossAxis: true,
            mainAxis: true
        })
    ]
});
</script>

<style lang="scss" scoped>
.hint {
    &__wrap {
        position: absolute;
        padding: 20px;
        border: 1px solid #686d6d;
        border-radius: 30px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
        background: #f8f8f8;
        max-width: 670px;
        width: 100%;
        z-index: 100;

        @media (max-width: 768px) {
            padding: 16px;
            max-width: 80vw;
        }
    }
}
</style>