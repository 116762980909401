<template>
    <div class="select">
        <div class="select__wrap">
            <label :for="id" class="select__label">
                {{ label }}
            </label>
            <select class="select__control" :id="id" @change="handleChange($event.target.value)">
                <option :value="option" v-for="option in options" :key="option">
                    {{ option }}
                </option>
            </select>
        </div>
    </div>
</template>

<script setup>
import { nanoid } from 'nanoid'
import { ref } from 'vue';

defineProps(['options', 'label', 'value'])

const emit = defineEmits(['update:value'])

const id = ref(nanoid())

const handleChange = (value) => {
    emit('update:value', value)
}
</script>

<style lang="scss" scoped>
.select {
    &__label {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    &__control {
        font-size: 14px;
        appearance: none;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L4.5 4L8 1' stroke='%2316171E' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right;
        padding-right: 20px;
        color: #16171E;

        @media (max-width:768x) {
            font-size: 22px;
        }
    }
}
</style>