import axios from "axios";
import md5 from 'js-md5'
import {local} from 'brownies';

axios.defaults.baseURL = 'https://api.directual.com/good/api/v5/data/';
const appId = '14411970-78ad-47b2-a55d-af4851f9ada2';

export default {
  namespaced: true,
  state: {
    authorized: false,
    educationUser_id: false,
    token: false,
    saveAuth: true,
    claimedRemindId: false,
    userFields: {
      certificate: '',
      middleName: '',
      email: '',
      placeOfWork: '',
      lastName: '',
      firstName: '',
      id: '',
      speciality: '',
      education: '',
      position: '',
      specialisation: '',
      phone: '',
      birthdayDate: 0,
      isVerified: false,
      isAllDocuments: false,
    },
  },
  getters: {
    getAuthToken(state){
      return state.token;
    },
    getEducationUserId(state){
      return state.educationUser_id;
    },
  },
  mutations: {
    fillAuthState(state, data){
      
      const tmpData = data.data.educationUser_id;
      state.authorized = true;
      state.educationUser_id = tmpData.id || null;
      
      state.userFields = {
        ...tmpData
      }
      
      state.token = data.data.token || null;
      if (state.saveAuth) {
        local.educationUser_id = tmpData.id || null;
        local.token = data.data.token || null;
      }
    }
  },
  actions: {
    initCache({state}){
      if (local.token) {
        state.authorized = true;
        state.educationUser_id = local.educationUser_id;
        state.token = local.token;
      }
    },
    logout({state}){
      state.authorized = false;
      state.educationUser_id = false;
      state.token = false;
      state.claimedRemindId = false;
      state.userFields = {};
      delete local.educationUser_id;
      delete local.token;
    },
    async login({commit}, payload){
      const data = JSON.parse(JSON.stringify(payload))
      data.password = md5(data.password)
      const response = await axios.post(`/educationUsersLogin/educationLoginUser?appID=${appId}`, data);
      if (response.data) {
        const respData = checkDirectualAPIResponse(response.data);
        if (respData.success) commit('fillAuthState', respData);
        return respData;
      }
    },
    async claimRemind({state}, email){
      state;
      const response = await axios.post(`/educationUsersRecoverPassword/educationUsersRecoverPasswordRequest?appID=${appId}`, {email});
      if (response.data && response.data.result[0].id) {
        state.claimedRemindId = response.data.result[0].id;
        return {success: true};
      }
      return {success: false};
    },
    async checkCode({state}, code){
      const payload = {
        id: state.claimedRemindId,
        inputCode: code
      }
      const response = await axios.post(`/educationUsersRecoverPassword/educationUsersRecoverPasswordResponse?appID=${appId}`, payload);
      if (response?.data?.result[0]) {
        const result = response?.data?.result[0]

        if (!result?.isCorrect) return {success: false};
  
        state.claimedRemindId = undefined;
        state.educationUser_id = result.educationUser_id;
        state.authorized = true;
        state.token = result.token;
        //TODO: GET AND SET educationUser_id
        return {success: true};
      }
      return {success: false};
    },
    async setPassword({state}, password){
      
      const token = state.token;
      const payload = {
        id: state.educationUser_id,
        password: md5(password)
      };
      try {
        const response = await axios.post(`/educationUsers/editEducationUser?appID=${appId}&sessionID=${token}`, payload);
        if (response.data && response.data.result[0]) {
          state.claimedRemindId = false;
          return {success: true};
        }
      } catch (e) {
        console.log(e);
      }
      return {success: false};
    },
    async register({state}, payload){
      state;
      const data = JSON.parse(JSON.stringify(payload));
      delete data.passwordConfirm;
      data.password = md5(data.password);
      if (payload.phone) {
        payload.phone = payload.phone.replace(/\D/g,'');
      }
      try {
        const response = await axios.post(`/educationUsersRegistration/educationRegisterUser?appID=${appId}`, data);
        if (response.data) {
          const result = checkDirectualAPIResponse(response.data);
          if (result.success) {
            state.authorized = true;
            state.token = response.data.result[0].token;
            state.educationUser_id = response.data.result[0].educationUser_id;
          }
          return result;
        }
      } catch (e){
        console.log(e);
      }
    },
    async fetchUserFields({state}){
      if (!state.authorized) return;
      const token = state.token;
      const userId = state.educationUser_id;
      try {
        const response = await axios.get(`/educationUsers/getEducationUserById?appID=${appId}&sessionID=${token}&userId=${userId}`);
        if (response.data) {
          if (response.data && response.data.payload[0]) {
            state.userFields = {
              ...state.userFields,
              ...response.data.payload[0],
            }
          }
        }
      } catch (e){
        console.log(e);
      }
    },
    async updateUserFields({state}, payload){
      
      if (!state.authorized) return;
      const token = state.token;
      const data = {...payload}
      if (data.phone) {
        data.phone = data.phone.replace(/\D/g,'');
      }
      if (data.id) {
        data.id = state.educationUser_id;
      }
      for (const fieldName in data) {
        if (fieldName.includes('file_')) {
          delete data[fieldName];
        }
      }
      try {
        const response = await axios.post(`/educationUsers/editEducationUser?appID=${appId}&sessionID=${token}`, data);
        if (response.data && response.data.result[0]) {
          state.userFields = {
            ...state.userFields,
            ...data
          }
          return {success: true};
        }
      } catch (e) {
        console.log(e);
      }
      return {success: false};
    },
    async updateUserDocuments({state}, {formData, callBack}){
      if (!state.authorized) return;
      const token = state.token;
      formData.append('id', state.educationUser_id);
      try {
        const options = {
          headers: {'Content-Type': 'multipart/form-data'},
          onUploadProgress: progressEvent => callBack((progressEvent.loaded / progressEvent.total) * 100)
        };
        const response = await axios.post(`/educationUsers/editEducationUser?appID=${appId}&sessionID=${token}`, formData, options);
        if (response.data && response.data.result[0]) {
          return {success: true};
        }
      } catch (e) {
        console.log(e);
      }
      return {success: false};
    }
  },
}

function checkDirectualAPIResponse(resp){
  if (resp.result && resp.result[0]) {
    const data = resp.result[0];
    if (data.rsp_code && data.rsp_code === '00') {
      return {
        success: true,
        message: resp.result[0].rsp_message || '',
        data
      };
    }
    if (data.rsp_code && data.rsp_code === '01') {
      return {
        success: false,
        message: resp.result[0].rsp_message || '',
        data
      };
    }
  }
  return {success: false};
}