<template>

  <div class="not-found__wrap">
      <h1 class="not-found__head">ошибка 404</h1>
      <p class="not-found__body">Кажется, что-то пошло не&nbsp;так.Страница, которую вы&nbsp;запрашиваете не&nbsp;существует. Возможно, страница устарела, была удалена или был введен неправильный адрес в&nbsp;адресной строке</p>
  </div>

</template>

<script>
export default {
  name: "NotFound",
}
</script>
<style lang="scss">
  .not-found {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(@/assets/img/images/bg-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    &__wrap {
      max-width: 814px;
      margin: 30px;
    }
    &__head{
      font-weight: 700;
      font-size: 88px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #EB5757;
      margin-bottom: 32px;
    }
    &__body{
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #333333;
    }

    @media (max-width: 728px) {
      &__head {
        font-size: 66px;
      }
      &__body {
        font-size: 16px;
      }
    }

    @media (max-width: 480px) {
      &__head {
        font-size: 36px;
        margin-bottom: 24px;
      }
      &__body {
        font-size: 14px;
      }
    }

  }

</style>
