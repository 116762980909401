<template>
    <div class="edu-event">
        <div class="edu-event__wrap">
            <div class="edu-event__head">
                <ul class="edu-event__tags">
                    <li class="edu-event__tag">
                        {{ startDay }}
                    </li>
                    <li class="edu-event__tag">
                        {{ item.timeStart }} - {{ item.timeEnd }}
                    </li>
                    <li class="edu-event__tag" v-if="item.isOnline">
                        Онлайн
                    </li>
                </ul>

                <div class="edu-event__title">
                    {{ item.title }}
                </div>

                <div class="edu-event__desc">
                    {{ item.description }}
                </div>

                <img class="edu-event__img" :src="item.cardImage_id?.urlLink || placeholderImg" alt="">
            </div>

            <div class="edu-event__body">

                <template v-if="item.educationSpeaker_ids">
                    <div class="edu-event__col" v-for="person in item.educationSpeaker_ids" :key="person.name">
                        <EventPerson :person="person" class="edu-event__event-person event-person" type="Спикер"
                            :name="person.name" :job="person.professionalEducation" :photo="person.photo_id" />
                    </div>
                </template>

                <div class="edu-event__col">
                    <EventPerson v-if="item.moderatorName"
                        class="edu-event__event-person edu-event__event-person--moderator event-person" type="Модератор"
                        :name="item.moderatorName" :job="item.moderatorPosition" :key="item.moderatorName" />

                    <template v-if="!userIsAuthorized">
                        <button class="edu-event__btn" @click="$router.push('/login')" v-if="item.isAllowEnroll">
                            Войти и записаться
                        </button>
                    </template>
                    <template v-else>
                        <template v-if="isSignedIn">
                            <button class="edu-event__btn" @click.prevent="toggleSign(item.id, item.title, idx)">
                                Отменить запись
                            </button>
                        </template>
                        <template v-else>

                            <template v-if="item.isOnline || (!item.isOnline && (availablePlaces > 0))">
                                <button v-if="item.isAllowEnroll" class="edu-event__btn"
                                    :class="{ 'link--unverified': !userIsVerified }"
                                    @click.prevent="toggleSign(item.id, item.title)">
                                    Записаться
                                </button>
                                <div class="edu-event__notice" v-else>
                                    запись откроется за неделю до мероприятия
                                </div>
                            </template>
                            <div v-else class="edu-event__notice">
                                Мест нет
                            </div>
                        </template>
                    </template>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import moment from "moment-with-locales-es6";
import EventPerson from './EventPerson.vue';
import { computed, onMounted, ref } from "vue";
import placeholderImg from '@/assets/img/placeholder/EduEvent.jpg'
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

moment.locale('ru')

const props = defineProps(['item'])
const emit = defineEmits(['vote'])

const store = useStore()
const router = useRouter()
const isSignedIn = ref(false)
const availablePlaces = ref(0)

const startDay = computed(() => {
    return moment(props.item.dateStart).clone().format('DD MMMM')
})

const userIsAuthorized = computed(() => {
    return store.state.user.authorized || false
})
const userIsVerified = computed(() => {
    return store.state.user.userFields.isVerified || false
})

const toggleSign = async (eventId, eventName) => {

    if (!userIsVerified.value) return;

    if (!store.state.user.authorized) {
        router.push('/login');
        return;
    }

    /* запись */
    if (!isSignedIn.value) {
        const result = await store.dispatch('events/signForEvent', { eventId });

        if (result.success) {
            isSignedIn.value = true;
            emit('vote', `
    <div class="popup__wrap">
      <p class="popup__title">Спасибо, вы записались на мероприятие «${eventName}»</p>
      <ul class="popup__list mb-0">
        <li>Информация о месте проведения и вашей записи отправлена вам на email</li>
        <li>За сутки до мероприятия мы отправим вам напоминание на указанный email адрес</li>
      </ul>
      <!--<a href="#" class="popup__link">вернуться на сайт</a>-->
    </div>`);
        }
        return;

    } else {
        const result = await store.dispatch('events/signForEvent', { eventId, isDeleted: true });
        if (result.success) {
            isSignedIn.value = false;
            emit('vote', `
    <div class="popup__wrap popup__wrap--center">
      <p class="popup__title mb-4">Вы отменили запись </p>
      <div class="popup__text mb-0">
        Вы отменили участие в мероприятии «${eventName}»
      </div>
    </div>`);
        }
    }
    return false;
}

onMounted(() => {
    console.log(props.item)
    if (store.state.user.educationUser_id && props.item.educationEventsParticipants_ids) {
        isSignedIn.value = props.item.educationEventsParticipants_ids.some(p => p.educationUser_id === store.state.user.educationUser_id) || false;
    }

    if (props.item.maxNumberOfParticipants) {
        const currentNumberOfParticipants = (!props.item.currentNumberOfParticipants || props.item.currentNumberOfParticipants <= 0) ? 0 : props.item.currentNumberOfParticipants;
        availablePlaces.value = props.item.maxNumberOfParticipants - currentNumberOfParticipants;
        availablePlaces.value = availablePlaces.value < 0 ? 0 : availablePlaces.value;
    }
})
</script>

<style lang="scss" scoped>
.edu-event {
    &__wrap {
        padding: 20px 40px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
        background: #fff;
        border-radius: 30px;

        @media (max-width: 768px) {
            padding: 16px;
        }
    }

    &__img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
    }

    &__head {
        padding: 20px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        min-height: 180px;
        color: #fff;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        z-index: 10;

        @media (max-width: 768px) {
            padding: 16px;
        }

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background-color: #4f4f4f;
            opacity: 0.4;
            z-index: -1;
        }
    }

    &__col {
        display: flex;
        flex-direction: column
    }

    &__tags {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__tag {
        padding: 4px 8px;
        border-radius: 50px;
        border: 1px solid #fff;
        font-weight: 700;
        font-size: 14px;
    }

    &__desc {
        font-size: 14px;
    }

    &__title {
        margin-top: auto;
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 22px;
    }

    &__body {
        display: flex;
        gap: 20px;
        justify-content: space-between;


        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__event-person {
        max-width: 290px;

        @media (max-width: 768px) {
            max-width: 100%;
        }

        &--moderator {
            max-width: 250px;

            @media (max-width: 768px) {
                max-width: 100%;
            }
        }
    }

    &__btn {
        padding: 10px 40px;
        background: #2194ff;
        border-radius: 50px;
        color: #fff;
        margin-top: auto;
        cursor: pointer;
        width: 100%;
        max-width: 250px;

        @media (max-width: 768px) {
            max-width: 100%;
        }
    }

    &__notice {
        font-size: 14px;
        margin-top: auto;
    }
}
</style>