<template>
  <section id="discuss" class="promo">
    <div class="wrapper">
      <div class="promo__wrap">
        <div class="promo__logos">
          <RouterLink to="/">
            <img src="@/assets/img/images/logo-1.svg" alt="Московский центр иновационных технологий в здравоохранении"
              width="288" height="60">
          </RouterLink>
          <img src="@/assets/img/images/logo-2.svg" alt="Комплекс социального развития Москвы" width="138" height="54"
            class="promo__logo--second">
        </div>
        <h1><span>Исследования – врачам</span>Всё о проведении клинических исследований</h1>
        <div class="promo__text">
          <p>Онлайн-платформа, позволяющая получить ответы на любые вопросы, связанные с проведением клинических
            исследований. Дискуссионная площадка со спикерами, обучающие семинары, тематические вопросы, обмен опытом,
            проработка юридических вопросов</p>
        </div>
      </div>

      <div class="promo__promo-links promo-links">
        <ul class="promo-links__list" :class="!userIsVerified && 'promo-links__list--unverified'">
          <li class="promo-links__item" v-for="item in promoLinks" :key="item.link">
            <a :href="item.link" class="promo-links__item-link" v-smooth-scroll>
              <img :src="item.bg" class="promo-links__item-img" alt="">
              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import link1img from "@/assets/img/images/link1.jpg"
import link2img from "@/assets/img/images/link2.jpg"
import link3img from "@/assets/img/images/link3.jpg"
import link4img from "@/assets/img/images/link4.jpg"
import link5img from "@/assets/img/images/link5.jpg"

export default {
  name: "promoSection",
  data() {
    return {
      links: [
        {
          name: "ШКОЛА «МОСКОВСКИЙ ИССЛЕДОВАТЕЛЬ»",
          link: "#school",
          bg: link1img
        },
        {
          name: "получить сертификат GCP",
          link: "#cert",
          bg: link2img,
          verifiedOnly: true
        },
        {
          name: "Полезные материалы",
          link: "#materials",
          bg: link4img
        },
        {
          name: "Стандартные операционные процедуры",
          link: "#sop",
          bg: link3img
        },
        {
          name: "Медиа",
          link: "#media",
          bg: link5img
        }
      ]
    }
  },
  computed: {
    userIsVerified() {
      return this.$store.state.user.userFields?.isVerified
        && (this.$store.state.user.userFields?.status === 'Верифицирован из списка')
    },
    promoLinks() {
      if (this.userIsVerified) return this.links;

      return this.links.filter(el => !el.verifiedOnly)
    }
  }
}
</script>

<style scoped lang="scss">
.promo {
  &__promo-links {
    position: relative;
  }
}

.promo-links {
  &__list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    position: absolute;
    z-index: 10;
    bottom: -220px;
    left: 0;
    right: 0;
    width: fit-content;
    margin: auto;

    &--unverified {
      grid-template-columns: repeat(4, 1fr);
      margin: auto;
      right: 0;
      left: 0;
      width: fit-content;

      .promo-links__item {
        @media(max-width:1200px) {
          flex: 0 0 45%;
        }
      }
    }

    @media (max-width: 1200px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      bottom: -300px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__item {
    @media (max-width: 1200px) {
      flex: 0 0 30%;
    }
  }

  &__item-link {
    position: relative;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
    padding: 20px;
    aspect-ratio: 1 / 1;
    max-height: 200px;
    display: flex;
    align-items: flex-end;
    border-radius: 12px;
    color: #333;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    text-decoration: none;

    @media (max-width: 1200px) {
      aspect-ratio: auto;
      height: 130px;
    }
  }

  &__item-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    border-radius: 12px;
    inset: 0;
    z-index: -1;
  }
}
</style>