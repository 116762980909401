<template>
    <div class="events-page" id="school" data-nav-section>

        <div class="events-page__wrap">
            <div class="wrapper">
                <nav class="events-page__breadcrumbs">
                    <router-link to="/#school" class="breadcrumbs__link">Школа</router-link>
                    <span class="breadcrumbs__link">Прошедшие мероприятия</span>
                </nav>

                <div class="events-page__head">
                    <h1 class="events-page__heading">
                        Прошедшие мероприятия
                    </h1>

                    <div class="events-page__filters">
                        <Select v-model:value="orderBy" class="events-page__select" label="Сортировка"
                            :options="orderingOptions" />
                    </div>
                </div>

                <div class="events-page__count">
                    Всего было: {{ events.length }} {{ plural(events.length, 'мероприятие', 'мероприятия', 'мероприятий') }}
                </div>

                <SearchForm class="events-page__search-form" v-model:value="query" />


                <ul class="events-page__list">
                    <EventItem v-for="item in events.slice(0, page * perPage)" :key="item.tile" :item="item"
                        class="events-page__event-item" />
                </ul>
                <button class="events-page__btn" @click.prevent="page += 1" v-if="page * perPage <= events.length">
                    Показать еще
                </button>
            </div>

        </div>

        <nav class="events-page__breadcrumbs events-page__breadcrumbs--bottom">
            <div class="wrapper">
                <router-link to="/" class="breadcrumbs__link">Школа</router-link>
                <span class="breadcrumbs__link">Прошедшие мероприятия</span>
            </div>
        </nav>
    </div>
</template>

<script setup>
import moment from 'moment-with-locales-es6';
import Select from '@/components/Form/Select.vue';
import SearchForm from '@/components/SearchForm.vue';
import { computed, ref } from 'vue';
import EventItem from '@/components/EventItem.vue';
import { useStore } from 'vuex';
import plural from 'plural-ru'

const orderingOptions = [
    'Сначала новые', 'Сначала старые'
]

const store = useStore()

const perPage = 6
const page = ref(1)
const orderBy = ref('Сначала новые')
const query = ref('')

const events = computed(() => {
    let result = store.state.events.events;

    result = result.filter(e => e.isDone === true);

    result.map(e => {
        e.dateRange = '';
        if (e.dateStart && moment(e.dateStart).isSame(e.dateEnd, 'day')) {

            e.dateRange += `${moment(e.dateStart).locale('ru').format('D MMMM YYYY')}`;

        } else if (e.dateStart && e.dateEnd && moment(e.dateStart).isSame() === moment(e.dateEnd).month()) {

            e.dateRange += moment(e.dateStart).locale('ru').format('D');
            e.dateRange += ` – ${moment(e.dateEnd).locale('ru').format('D MMMM YYYY')}`;

        } else {
            e.dateRange = moment(e.dateStart).locale('ru').format('D MMMM');
            if (e.dateEnd) {
                e.dateRange = moment(e.dateStart).locale('ru').format('D.MM');
                e.dateRange += ` – ${moment(e.dateEnd).locale('ru').format('D.MM')}`;
            }
        }

        if (e.description) {
            e.description = e.description.replace('\n\n', '<br/>');
        }

    });

    result = result.sort((a, b) => {
        if (orderBy.value === 'Сначала старые') {
            return a.dateStart - b.dateStart;
        }

        if (orderBy.value === 'Сначала новые') {
            return b.dateStart - a.dateStart;
        }

        return true
    })

    return result.filter((el) => {
        if (!query.value) return true
        return el.title.toLowerCase().includes(query.value.toLowerCase()) || el.description.toLowerCase().includes(query.value.toLowerCase())
    })
})
</script>

<style lang="scss" scoped>
.events-page {
    &__wrap {
        padding: 100px 0;

        @media (max-width: 600px) {
            padding: 40px 0;
        }
    }

    &__breadcrumbs {
        margin-bottom: 60px;

        &--bottom {
            padding: 20px 0;
            border-top: 1px solid rgba(#16171E, 0.2);
            border-bottom: 1px solid rgba(#16171E, 0.2);
            margin-bottom: 0;
        }
    }

    &__heading {
        margin-bottom: 0;
        font-size: 30px;

        @media (max-width: 768px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }

    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;
        }
    }

    &__filters {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: auto;

        @media (max-width: 768px) {
            margin-left: 0;
        }
    }

    &__count {
        color: rgba(22, 23, 30, 0.5);
        margin-bottom: 60px;
    }

    &__search-form {
        width: 100%;
        max-width: 350px;
        margin: auto;
        margin-bottom: 70px;

        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }

    &__list {
        margin: 0;
        list-style: none;
        padding: 0;
        display: grid;
        align-items: stretch;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        @media (max-width:1200px) {
            gap: 20px;
        }

        @media (max-width:991px) {
            grid-template-columns: 1fr;
        }
    }

    &__btn {
        background: #F1F3F7;
        border-radius: 40px;
        width: 100%;
        max-width: 310px;
        color: #1274E0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
        padding: 18px 32px;
    }
}
</style>