import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueYandexMetrika from 'vue-v3-yandex-metrika'

import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue3SmoothScroll from "vue3-smooth-scroll";
import Maska from 'maska'
import VueProgressBar from "@aacassandra/vue3-progressbar";

import "./assets/css/style.css";
import "./assets/css/custom.css";

//axios.defaults.baseURL = 'https://api.directual.com';

const app = createApp(App).use(store).use(router).use(VueAxios, axios)
app.use(Vue3SmoothScroll, {
  updateHistory: false,
  offset: -70,
});
app.use(Maska);
app.use(VueProgressBar, {
  color: "#094DF1",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
});
app.use(VueYandexMetrika, {
  id: 89598583,
  router: router,
  env: process.env.NODE_ENV
  // other options
});

app.mount('#app');