<template>

  <div class="login login--pass">
    <div class="login__logo">
      <RouterLink to="/">
        <img src="@/assets/img/images/logo-1.svg" alt="Московский центр иновационных технологий в здравоохранении" width="288" height="60">
      </RouterLink>
      <img src="@/assets/img/images/logo-2.svg" alt="Комплекс социального развития Москвы" width="138" height="54">
    </div>

    <template v-if="step===1">
      <p class="login__title">Восстановление пароля</p>
      <form action="#" class="form">
        <div class="form__fields">
          <div class="form__field" :class="{ 'form__field--error' : v$.login.$errors.length }">
            <p class="form__label form__label--pass">Введите электронный адрес, на который мы вышлем вам код для сброса пароля</p>
            <label for="email" class="form__label">Электронная почта</label>
            <input type="email" v-model="v$.login.$model" id="email" class="form__input">
          </div>
        </div>
        <button @click.prevent="claimRemind" :disabled="v$.login.$errors.length || !v$.login.$model.length" class="btn form__btn">Продолжить</button>
        <p class="form__comment">Не приходит код? <a href="#" @click.prevent="claimRemind">Выслать повторно</a></p>
      </form>

    </template>
    <template v-if="step===2">
      <p class="login__title">Восстановление пароля</p>
      <form action="#" class="form">
        <div class="form__fields">
          <p class="form__label form__label--pass">На ваш электронный адрес <a href="#">{{login}}</a>
            отправлено письмо с кодом для восстановления пароля. <a @click="step=1" href="#">Изменить email</a> </p>
          <div class="form__field" :class="{ 'form__field--error' : v$.code.$errors.length }">
            <label for="email" class="form__label">Введите код</label>
            <input type="email" v-model="v$.code.$model" id="email" class="form__input">
          </div>
          <p v-if="invalidCode">Некорректный код</p>
        </div>
        <button @click.prevent="checkCode" :disabled="v$.code.$errors.length || !v$.code.$model.length" class="btn form__btn">Продолжить</button>
        <p class="form__comment">Не приходит код? <a href="#" @click.prevent="claimRemind">Выслать повторно</a></p>
      </form>

    </template>
    <template v-if="step===3">
      <p class="login__title">Восстановление пароля</p>
      <form action="#" class="form">
        <div class="form__fields">
          <div class="form__field" :class="{ 'form__field--error' : v$.password.$errors.length }">
            <label for="password" class="form__label">Придумайте новый пароль</label>
            <input type="password" v-model="v$.password.$model" id="password" class="form__input" placeholder="Введите новый пароль">
          </div>
          <div class="form__field" :class="{ 'form__field--error' : v$.passwordConfirm.$errors.length }">
            <label for="password2" class="form__label">Подтвердите новый пароль</label>
            <input type="password" v-model="v$.passwordConfirm.$model" id="password2" class="form__input" placeholder="Подтвердите новый пароль">
          </div>
        </div>
        <button type="submit" @click.prevent="setPassword" :disabled="v$.password.$errors.length || v$.passwordConfirm.$errors.length || !v$.password.$model.length"  class="btn form__btn">Изменить пароль</button>
      </form>

    </template>
    <template v-if="step===4">
      <div class="login__ready">
        <p>Пароль успешно изменен</p>
      </div>
      <a href="#" @click.prevent="$router.push('/')" class="btn login__btn" >Завершить и перейти на главную</a>
    </template>
  </div>



</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email,  minLength, sameAs} from '@vuelidate/validators'
export default {
  name: "RemindingView",
  setup () {
    return { v$: useVuelidate() }
  },
  mounted(){
  },
  data(){
    return {
      step: 1,
      successRemind: false,
      login: '',
      code: '',
      password: '',
      passwordConfirm: '',
      invalidCode: false,
    }
  },
  validations() {
  return {
    login: {
      required, email
    },
    code: {
      required,
    },
    password: {
      required,
      min: minLength(8)
    },
    passwordConfirm: {
      required,
      min: minLength(8),
      sameAs: sameAs(this.password)
    },
    /*formPayload: {
      lastName: {
        required
      },
      firstName: {
        required
      },
      birthdayDate: {
        required
      },
      email: {
        required, email
      },
      phone: {
        required
      },
      placeOfWork: {
        required
      },
      speciality: {
        required
      },
      education: {
        required
      },
      specialisation: {
        required
      },
      position: {
        required
      },
      password: {
        required,
        min: minLength(8)
      },
      passwordConfirm: {
        required,
        min: minLength(8),
        sameAs: sameAs(this.formPayload.password)
      },
    },*/
  }
},
  methods: {
    async claimRemind(){
      const result = await this.$store.dispatch('user/claimRemind', this.login);
      if (result.success) {
        this.step++;
      }
    },
    async checkCode(){
      const result = await this.$store.dispatch('user/checkCode', this.code);
      if (result.success) {
        this.step++;
      } else {
        this.invalidCode = true;
      }
    },
    async setPassword(){
      if (this.password !== this.passwordConfirm){
        alert('Пароли не совпадают');
        return;
      }
      const result = await this.$store.dispatch('user/setPassword', this.password);
      if (result.success) {
        this.step++;
      }
    },
  }
}
</script>

<style scoped>

</style>