<template>
  <template v-if="!successLogin">
    <div class="login">
      <RouterLink :to="prevRoute" class="login__close">
        <svg viewBox="0 0 32 32">
          <path stroke="#000" d="M10,10 L22,22 M22,10 L10,22"></path>
        </svg>
      </RouterLink>
      <div class="login__logo">
        <RouterLink to="/">
          <img src="@/assets/img/images/logo-1.svg" alt="Московский центр иновационных технологий в здравоохранении"
            width="288" height="60">
        </RouterLink>
        <img src="@/assets/img/images/logo-2.svg" alt="Комплекс социального развития Москвы" width="138" height="54">
      </div>
      <p class="login__slogan">Исследования – врачам</p>
      <p class="login__subtitle">Онлайн платформа, предоставляющая наиболее полноценную и актуальную информацию по
        проведению клинических исследований</p>
      <form action="#" class="form">
        <div class="form__fields">
          <div class="form__field" :class="{ 'form__field--error': v$.formFields.login.$errors.length }">
            <label for="login" class="form__label">Логин</label>
            <input type="text" id="login" class="form__input" v-model="v$.formFields.login.$model"
              placeholder="Ваша электронная почта или номер телефона">
          </div>
          <div class="form__field" :class="{ 'form__field--error': v$.formFields.password.$errors.length }">
            <label for="password" class="form__label">Ваш пароль</label>
            <input type="password" v-model="v$.formFields.password.$model" id="password" class="form__input"
              placeholder="Введите ваш пароль">
          </div>
          <div class="form__line">
            <input type="checkbox" id="checkbox" class="form__checkbox" :checked="this.$store.state.user.saveAuth">
            <label for="checkbox" class="form__checkbox-label">Запомнить меня</label>
            <router-link :to="`/reminding`" class="form__link">Восстановить пароль</router-link>
          </div>
        </div>
        <template v-if="error">
          <div class="error">
            <p>Вы указали неправильный email или пароль, попробуйте снова</p>
          </div>
        </template>
        <button type="submit" @click.prevent="login" class="btn form__btn"
          :disabled="v$.formFields.$invalid">Войти</button>
        <p class="form__comment">Нет аккаунта? <router-link :to="`/register`">Зарегистрироваться</router-link></p>
      </form>
    </div>
    <div>
    </div>
  </template>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
  required,
  minLength
} from '@vuelidate/validators'
export default {
  name: "LoginView",
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      successLogin: false,
      formFields: {
        login: '',
        password: ''
      },
      error: false,
      prevRoute: ''
    }
  },
  validations() {
    return {
      formFields: {
        login: {
          required,
        },
        password: {
          required,
          min: minLength(6)
        },
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from !== '/login') {
        vm.prevRoute = from.fullPath
        return
      }

      vm.prevRoute = '/'
    })
  },
  methods: {
    async login() {
      const formFields = this.formFields;
      const result = await this.$store.dispatch('user/login', formFields);
      if (result.success) {
        this.error = false;
        this.successLogin = true;
        this.$router.push('/');
        return;
      }
      this.error = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  color: #d00909;
}

.login {
  position: relative;

  &__close {
    position: absolute;
    top: 40px;
    right: -200px;
    width: 60px;
    height: 60px;

    @media (max-width: 768px) {
      top: 100px;
      right: 10px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>