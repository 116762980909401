<template>


  <nav class="breadcrumbs">
    <div class="wrapper">
      <router-link to="/profile" class="breadcrumbs__link">Профиль пользователя</router-link>
      <span class="breadcrumbs__link">Изменение пароля</span>
    </div>
  </nav>

  <template v-if="!successUpdated">
    <div class="password">
      <div class="wrapper">
        <div class="password__wrap">

          <div class="password__comment">
            <ul class="">
              <li>Пароль не может быть короче 8 символов</li>
              <li>В пароле должна быть минимум одна цифра</li>
              <li>В пароле должна быть минимум одна прописная буква</li>
              <li>В пароле должна быть минимум одна строчная буква</li>
            </ul>
          </div>

          <form action="#" class="form form--cabinet">
            <div class="form__field form__field--nopass" :class="{ 'form__field--error' : v$.password.$errors.length, 'form__field--pass': passwordText }">
              <label for="pass2" class="form__label">Новый пароль</label>
              <input :type="passwordText ? `text` : `password`" v-model="v$.password.$model" id="pass2" class="form__input" autocomplete="one-time-code">
              <span class="show_pass" @click="passwordText = !passwordText"></span>
            </div>
            <div class="form__field form__field--nopass" :class="{ 'form__field--error' : v$.passwordConfirm.$errors.length, 'form__field--pass' : passwordConfirmText }">
              <label for="pass3" class="form__label">Повторите новый пароль</label>
              <input :type="passwordConfirmText ? `text` : `password`" v-model="v$.passwordConfirm.$model" id="pass3" class="form__input" autocomplete="one-time-code">
              <span class="show_pass" @click="passwordConfirmText = !passwordConfirmText"></span>
            </div>

            <div class="form__btns">
              <router-link to="/profile" class="form__backbtn">Назад</router-link>
              <button type="submit" :disabled="v$.$errors.length" @click.prevent="setPassword" class="btn form__unpdate">Сохранить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="notification">
      <span>Пароль изменен</span>
      <button type="button" class="notification__close"></button>
    </div>
  </template>



</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs, helpers } from '@vuelidate/validators'
export default {
  name: "PasswordView",
  data(){
    return {
      successUpdated: false,
      error: false,
      password: '',
      passwordText: false,
      passwordConfirm: '',
      passwordConfirmText: false,
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  methods: {
    async setPassword(){
      if (this.password !== this.passwordConfirm){
        alert('Пароли не совпадают');
        return;
      }
      const result = await this.$store.dispatch('user/setPassword', this.password);
      if (result.success) {
        this.successUpdated = true;
        setTimeout(this.$router.push('/profile'),2000);
      }
    },
  },
  validations() {
    return {
      password: {
        required,
        min: minLength(8),
        containsPasswordRequirement: helpers.withMessage(
            () => `The password requires an uppercase, lowercase, number and special character`,
            (v) => /^(?=.*\d)(?=.*[a-zа-я])(?=.*[A-ZА-Я])[0-9a-zA-Zа-яА-Я!@#$%^&*()-]{8,}$/.test(v)
        ),
      },
      passwordConfirm: {
        required,
        min: minLength(8),
        sameAs: sameAs(this.password)
      },
    }
  },
}
</script>

<style scoped>
  .breadcrumbs {
    margin-top: 0;
    padding-top: 163px;
  }
  .password__comment {
    position: relative;
    color: #828282;
    padding-top: 36px;
    padding-left: 0;
    margin-top: 0px;
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif; }
  .password__comment ul {
    margin: 0;
    padding: 0;
    padding-left: 20px; }
  .password__comment ul li {
    margin-bottom: 5px; }
  .password__comment::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-image: url(@/assets/img/icons/error_outline.svg);
    background-repeat: no-repeat;
    background-size: contain; }
  .password__comment--top {
    top: 5px;
    bottom: auto;
    padding-top: 0;
    padding-left: 36px; }

</style>