import axios from 'axios';

axios.defaults.baseURL = 'https://api.directual.com/good/api/v5/data/';
const appId = '14411970-78ad-47b2-a55d-af4851f9ada2';

export default {
  namespaced: true,
  state: {
    sop: [],
    instructions: [],
    media: [],
    announces: [],
  },
  getters: {
    getSop(state) {
      return state.sop;
    },
    getInstructions(state) {
      return state.instructions;
    },
    getMedia(state) {
      return state.media;
    },
    getAnnounces(state) {
      return state.announces;
    },
    getSopFromCacheById: () => (id) => {
      const cacheSop = JSON.parse(getFromLocalStorage('sop'));
      return cacheSop.find((e) => e.id === id);
    },
    getInstructionsFromCacheById: () => (id) => {
      const cacheInstructions = JSON.parse(getFromLocalStorage('instructions'));
      return cacheInstructions.find((e) => e.id === id);
    },
    getMediaFromCacheById: () => (id) => {
      const cacheMedia = JSON.parse(getFromLocalStorage('media'));
      return cacheMedia.find((e) => e.id === id);
    },
    getAnnouncesFromCacheById: () => (id) => {
      const cache = JSON.parse(getFromLocalStorage('announces'));
      return cache.find((e) => e.id === id);
    },
  },
  mutations: {},
  actions: {
    async fetchSop({ state, rootGetters }) {
      if (state.sop && state.sop.length) return;
      const token = rootGetters['user/getAuthToken'];
      const response = await axios.get(
        `/educationSop/getEducationSopActive?appID=${appId}&sessionID=${token}&pageSize=1000`
      );
      if (response.data && response.data.payload) {
        state.sop = response.data.payload.map((el) => {
          return {
            ...el,
            type: 'СОП',
          };
        });
        saveToLocalStorage(
          'sop',
          response.data.payload.map((el) => {
            return {
              ...el,
              type: 'СОП',
            };
          })
        );
      }
    },
    async fetchInstructions({ state, rootGetters }) {
      if (state.instructions && state.instructions.length) return;
      const token = rootGetters['user/getAuthToken'];
      const response = await axios.get(
        `/eductionSection/getEducationSections?appID=${appId}&sessionID=${token}&pageSize=1000&pageSize=1000`
      );
      if (response.data && response.data.payload) {
        state.instructions = response.data.payload;
        saveToLocalStorage('instructions', response.data.payload);
      }
    },
    async fetchMedia({ state, rootGetters }) {
      if (state.media && state.media.length) return;
      const token = rootGetters['user/getAuthToken'];
      const response = await axios.get(
        `/educationMedia/getEducationMedia?appID=${appId}&sessionID=${token}&pageSize=1000`
      );

      if (response.data && response.data.payload) {
        state.media = response.data.payload;
        saveToLocalStorage('media', response.data.payload);
      }
    },
    async fetchAnnounces({ state, rootGetters }) {
      if (state.announces && state.announces.length) return;
      const token = rootGetters['user/getAuthToken'];
      const response = await axios.get(
        `/educationAnonce/getEducationAnonce?appID=${appId}&sessionID=${token}&pageSize=1000`
      );
      if (response.data && response.data.payload) {
        state.announces = response.data.payload;
        saveToLocalStorage('announces', response.data.payload);
      }
    },
  },
};

function getFromLocalStorage(key) {
  return localStorage.getItem(key);
}
function saveToLocalStorage(key, data) {
  if (!key || !data) return;
  localStorage.setItem(key, JSON.stringify(data));
}
