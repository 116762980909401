<template>
  <div class="file-preview">
    <a :href="fileUrl" download="download" class="file-preview__btn">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 8L15.59 9.41L17.17 11H9V13H17.17L15.59 14.58L17 16L21 12L17 8ZM5 5H12V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12V19H5V5Z"
          fill="#fff"
        />
      </svg>
    </a>

    <div>
      <iframe width="100%" class="file-preview__frame" :src="content" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import mammoth from 'mammoth';

const route = useRoute();
const content = ref('');

const fileUrl = computed(() => {
  return route.params.url.join('/');
});


const handlePreview = async () => {
    const req = await fetch(fileUrl.value)

    const resp = await req.arrayBuffer()

    const result = await mammoth
    .convertToHtml({ arrayBuffer: resp })

    const html = result.value

    var blob = new Blob([html], { type: 'text/html' });
    var url = URL.createObjectURL(blob);

    content.value = url
};


onMounted(() => {
  handlePreview();
})
</script>

<style lang="scss" scoped>
.file-preview {
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  position: relative;

  &__frame {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: block;
    height: 100vh;
  }

  &__btn {
    position: absolute;
    right: 12px;
    top: 12px;
    transform: rotate(90deg);
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
