import axios from "axios";

axios.defaults.baseURL = 'https://api.directual.com/good/api/v5/data/';
const appId = '14411970-78ad-47b2-a55d-af4851f9ada2';
const tmpSessionId = 'bef3d3cb-b1c2-4a5a-8c75-dbad3427723c'; //TODO: убрать из запросов которые должны быть анонимными

export default {
  namespaced: true,
  state: {
    id: '', //educationThemeWeek_id
    dateStart: 0,
    dateEnd: 0,
    educationTheme_ids: [],
    suggestedEducationTheme_ids: [],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async fetchInit({state}){
      try {
        const response = await axios.get(`/educationThemeWeek/getCurrentEducationThemeWeek?appID=${appId}&sessionID=${tmpSessionId}&pageSize=1000`);
        if (response.data && response.data.payload) {
          const payload = response.data.payload[0];
          state.id = payload.id;
          state.dateStart = payload.dateStart;
          state.dateEnd = payload.dateEnd;
          state.educationTheme_ids = payload.educationTheme_ids;
          state.suggestedEducationTheme_ids = payload.suggestedEducationTheme_ids;

          console.log(payload)
        }
      } catch (e){
        console.log(e);
      }
    },
    async vote({rootGetters}, payload){
      try {
        const token = rootGetters["user/getAuthToken"];
        const response = await axios.post(`/educationThemeVote/editEducationThemeVote?appID=${appId}&sessionID=${token}`, payload);
        if (response.data && response.data.result[0]) {
          return {success: true};
        }
        return {success: false};
      } catch (e){
        console.log(e);
      }
    },
    async suggestTopic({state, rootGetters}, name){
      try {
        const token = rootGetters["user/getAuthToken"];
        const author_id = rootGetters["user/getEducationUserId"];
        const payload = {
          name,
          author_id,
          educationThemeWeek_id: state.id,
        }
        const response = await axios.post(`/educationTheme/addEducationTheme?appID=${appId}&sessionID=${token}`, payload);
        if (response.data && response.data.result[0]) {
          return {success: true};
        }
        return {success: false};
      } catch (e){
        console.log(e);
      }
    },
  },
}