<template>
    <div class="sop-page" id="sop" data-nav-section>

        <div class="sop-page__wrap">
            <div class="wrapper">
                <nav class="sop-page__breadcrumbs">
                    <router-link to="/#materials" class="breadcrumbs__link" v-smooth-scroll>Полезные материалы</router-link>
                    <span class="breadcrumbs__link">Стандартные операционные процедуры</span>
                </nav>

                <div class="sop-page__head">
                    <h1 class="sop-page__heading">
                        Стандартные операционные процедуры
                    </h1>
                </div>

                <SearchForm class="sop-page__search-form" v-model:value="query" />

                <ul class="sop-page__list">
                    <MediaItem v-for="item in sop" :key="item.tile" :item="item"
                        :sticked="item.name.toLowerCase().includes('методические рекомендации по разработке макета')"
                        class="sop-page__media-item" />
                </ul>
            </div>

        </div>

        <nav class="sop-page__breadcrumbs sop-page__breadcrumbs--bottom">
            <div class="wrapper">
                <router-link to="/#sop" class="breadcrumbs__link">Полезные материалы</router-link>
                <span class="breadcrumbs__link">Стандартные операционные процедуры</span>
            </div>
        </nav>
    </div>
</template>

<script setup>
import SearchForm from '@/components/SearchForm';
import { computed, ref } from 'vue';
import MediaItem from '@/components/MediaItem';
import { useStore } from 'vuex';

const store = useStore()
const query = ref('')

const sop = computed(() => {
    let result = store.state.materials.sop;
    result = result.sort((a) => a.name.toLowerCase().includes('методические рекомендации по разработке макета') ? -1 : 1)

    return result.filter((el) => {
        if (!query.value) return true
        return el.name.toLowerCase().includes(query.value.toLowerCase())
    })
})
</script>

<style lang="scss" scoped>
.sop-page {
    &__wrap {
        padding: 100px 0;

        @media (max-width: 600px) {
            padding: 40px 0;
        }
    }

    &__breadcrumbs {
        margin-bottom: 60px;

        &--bottom {
            padding: 20px 0;
            border-top: 1px solid rgba(#16171E, 0.2);
            border-bottom: 1px solid rgba(#16171E, 0.2);
            margin-bottom: 0;
        }
    }

    &__heading {
        margin-bottom: 0;
        font-size: 30px;

        @media (max-width: 768px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }

    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 70px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 30px;
        }
    }

    &__filters {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: auto;

        @media (max-width: 768px) {
            margin-left: 0;
        }
    }

    &__count {
        color: rgba(22, 23, 30, 0.5);
        margin-bottom: 60px;
    }

    &__search-form {
        width: 100%;
        max-width: 350px;
        margin: auto;
        margin-bottom: 70px;

        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }

    &__list {
        margin: 0;
        list-style: none;
        padding: 0;
        display: grid;
        align-items: stretch;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;

        @media (max-width:1200px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width:991px) {
            grid-template-columns:  1fr 1fr;
        }

        @media (max-width: 568px) {
            grid-template-columns: 1fr;
        }
    }

    &__btn {
        background: #F1F3F7;
        border-radius: 40px;
        width: 100%;
        max-width: 310px;
        color: #1274E0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
        padding: 18px 32px;
    }
}
</style>