<template>

  <section class="cabinet" id="tabs">
    <div v-if="notificationOpened" class="notification">
      <span>Данные успешно обновлены</span>
      <button @click.prevent="notificationOpened = false" type="button" class="notification__close"></button>
    </div>
    <div class="wrapper">
      <ul class="cabinet__tabs">
        <li class="cabinet__name" :class="{ 'ui-tabs-active' : tabIdx === 1 }">
          <a href="#" @click.prevent="tabIdx = 1">Профиль</a>
        </li>
        <li class="cabinet__name" :class="{ 'ui-tabs-active' : tabIdx === 2 }" v-if="userFields?.certificate_id?.urlLink">
          <a href="#" @click.prevent="tabIdx = 2">Сертификат</a>
        </li>
      </ul>
      <template v-if="tabIdx === 1">
        <div class="cabinet__field" id="tabs-1">
          <div class="cabinet__title">
            <span>{{userFields.lastName}} {{ userFields.firstName }} {{ userFields.middleName }}</span>
            <p v-if="this.$store.state.user.userFields.isVerified" class="cabinet__label">Верифицирован</p>
            <p v-else class="cabinet__label cabinet__label--unverified">Не верифицирован</p>
          </div>
          <form action="#" class="form form--cabinet">
            <div class="form__columns">
              <div class="form__column">
                <div class="form__fields">
                  <div class="form__line">
                    <div class="form__field">
                      <label for="name" class="form__label" :class="{ 'form__field--error' : v$.userFields.firstName.$errors.length }">Имя</label>
                      <input type="text" v-model="v$.userFields.firstName.$model" id="name" class="form__input" disabled>
                    </div>
                    <div class="form__field" :class="{ 'form__field--error' : v$.userFields.lastName.$errors.length }">
                      <label for="surname" class="form__label">Фамилия</label>
                      <input type="text" v-model="v$.userFields.lastName.$model" id="surname" class="form__input" disabled>
                    </div>
                    <div class="form__field">
                      <label for="patronic" class="form__label">Отчество</label>
                      <input type="text" v-model="userFields.middleName" id="patronic" class="form__input" disabled>
                    </div>
                  </div>
                  <div class="form__field" :class="{ 'form__field--error' : v$.userFields.birthdayDate.$errors.length }">
                    <label for="date" class="form__label">Дата рождения</label>
                    <input type="date" v-model="v$.userFields.birthdayDate.$model" id="date" class="form__input form__input--date" disabled>
                  </div>
                  <div class="form__field" :class="{ 'form__field--error' : v$.userFields.phone.$errors.length }">
                    <label for="phone" class="form__label">Контактный телефон</label>
                    <input type="tel" v-model="v$.userFields.phone.$model" id="phone" class="form__input" v-maska="['+7 (###) ###-##-##', '+7 (###) ###-##-##']">
                  </div>

                  <pre v-show="false">
                  {{userObj}}
                  {{userFields}}
                  </pre>

                  <div class="form__field" :class="{ 'form__field--error' : v$.userFields.snils.$errors.length }">
                    <label for="snils" class="form__label">СНИЛС*</label>
                    <input type="tel" v-model="v$.userFields.snils.$model" id="snils" class="form__input" required="" v-maska="['###-###-### ##', '###-###-### ##']" placeholder="___-___-___ __">
                  </div>

                  <div class="form__field" :class="{ 'form__field--error' : v$.userFields.email.$errors.length }">
                    <label for="email" class="form__label">Email</label>
                    <input type="email" v-model="v$.userFields.email.$model" id="email" class="form__input"  disabled>
                  </div>
                </div>
                <div class="form__fields">
                  <div class="form__field" :class="{ 'form__field--error' : v$.userFields.placeOfWork.$errors.length }">
                    <label for="work" class="form__label">Место работы</label>

                    <VueMultiselect
                        @select="changeMultiselect($event, 'placeOfWork')"
                        v-model="$store.state.user.userFields.placeOfWork"
                        :options="medOrgs"
                        placeholder="Выбрать из списка"
                        :showLabels="false"
                    >
                    </VueMultiselect>

                  </div>
                  <div class="form__field" :class="{ 'form__field--error' : v$.userFields.position.$errors.length }">
                    <label for="position" class="form__label">Должность</label>
                    <input type="text" v-model="v$.userFields.position.$model" id="position" class="form__input"  >
                  </div>
                  <div class="form__field" :class="{ 'form__field--error' : v$.userFields.education.$errors.length }">
                    <label class="form__label">Образование</label>
                    <VueMultiselect

                        @select="changeMultiselect($event, 'education')"
                        v-model="$store.state.user.userFields.education"
                        :options="profs"
                        placeholder="Выбрать из списка"
                        :select-label="'Выбрать'"
                        :selected-label="'Выбрано'"
                        :deselect-label="``"
                        :showLabels="false"
                    >
                    </VueMultiselect>
                  </div>
                  <div class="form__field" :class="{ 'form__field--error' : v$.userFields.specialisation.$errors.length }">
                    <label for="speciality" class="form__label">Специальность</label>
                    <input type="text" v-model="v$.userFields.specialisation.$model" id="speciality" class="form__input"  >
                  </div>
                  <div class="form__field" :class="{ 'form__field--error' : v$.userFields.speciality.$errors.length }">
                    <label for="specialisation" class="form__label">Профессиональная переподготовка (специальность)</label>
                    <input type="text" v-model="v$.userFields.speciality.$model" id="specialisation" class="form__input"  >
                  </div>
                  <div class="form__field"  :class="{ 'form__field--error' : v$.userFields.certificate.$errors.length }">
                    <label for="certificate" class="form__label">Наличие действующего сертификата специалиста/аккредитация</label>
                    <input type="text" v-model="v$.userFields.certificate.$model" id="certificate" class="form__input"  >
                  </div>
                </div>
                <button type="submit" @click.prevent="updateUser" class="btn form__save">Сохранить изменения</button>
              </div>
              
            </div>
          </form>
        </div>
      </template>
      <template v-if="tabIdx === 2">
        <a :href="userFields?.certificate_id?.urlLink" target="_blank" class="btn form__save">Скачать сертификат</a>
      </template>
    </div>
  </section>

</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<script>
import { mask } from 'maska';
import moment from "moment-with-locales-es6";
import VueMultiselect from 'vue-multiselect';
import useVuelidate from '@vuelidate/core'
import { required, email,} from '@vuelidate/validators'

export default {
  name: "ProfileView",
  components: { VueMultiselect },
  setup () {
    return { v$: useVuelidate() }
  },
  created() {
    this.$store.dispatch('user/fetchUserFields');


  },
  watch: {
    userFields(v){
      for (const fieldName in v) {
        if (fieldName.includes('file_')) {
          this.formFieldsMap.map(e => {
            if (e.fieldName === fieldName) {
              e.isUploaded = true;
              e.data = v[fieldName];
              if (v[fieldName].originalFileName) {
                const fileExt = /(?:\.([^.]+))?$/.exec(v[fieldName].originalFileName)[1].toLowerCase();
                if (['jpg','jpeg','png'].includes(fileExt)) {
                  e.fileType = 'img';
                } else {
                  e.fileType = 'text';
                }
              }
            }
          });
        }
      }
    }
  },
  computed: {
    canViewDocumentsTab(){
      const userFields = this.$store.state.user.userFields;
      if (userFields?.status === 'Верифицирован из списка') return true;
      if (userFields?.status === 'Верифицирован' && userFields?.isAllDocuments) return true;
      return false;
    },
    userObj(){
      let obj =  {...this.$store.state.user.userFields};
      if (obj.birthdayDate) obj.birthdayDate = moment(obj.birthdayDate).format("YYYY-MM-DD");
      if (obj?.phone) {
        obj.phone = mask(obj.phone, '+7 (###) ###-##-##');
      }
      delete obj.status;
      delete obj.isVerified;
      delete obj.isAllDocuments;
      //eslint-disable-next-line
      this.userFields = obj;
      console.log(this.userFields)
      return obj;
    },
  },
  data(){
    return {
      userFields: {},
      notificationOpened: false,
      file: false,
      tabIdx: 1,
      formData: new FormData(),
      avatarPreview: false,
      progressBar: 0,
      formFieldsMap: [
        {
          fieldName: 'file_educationApplication',
          name: 'Заявление о зачислении на обучение, подписанное личной подписью поступающего, с отметкой о согласии на обработку персональных данных.docx',
          template_file: '/downloads/doc_templates/zajavlenie_o_zachislenii.docx'
        },
        {
          fieldName: 'file_trip',
          name: 'Путёвка (для лиц, обучающихся за счёт бюджета города Москвы).pdf',
          template_file: '/downloads/doc_templates/putjovka.docx'
        },
        {
          fieldName: 'file_passport',
          name: 'Копия паспорта (первая страница и страница с пропиской)',
        },
        {
          fieldName: 'file_education',
          name: 'Копия документа государственного образца об образовании (в соответствии с требованием программы обучения)',
        },
        {
          fieldName: 'file_diploma',
          name: 'Копия диплома об окончании ординатуры (при наличии)',
        },
        {
          fieldName: 'file_certificate',
          name: 'Копия действующего сертификата специалиста или копия свидетельства об аккредитации специалиста (для медицинских и фармацевтических работников)',
        },
        {
          fieldName: 'file_qualification',
          name: 'Копия документа о квалификации (удостоверение о повышении квалификации, подтверждающее освоение дополнительной профессиональной программы)',
        },
        {
          fieldName: 'file_employment',
          name: 'Копия трудовой книжки, заверенной сотрудником отдела кадров, содержащей запись «работает по настоящее время» или справка о наличии стажа работы в соответствии с квалификационными требованиями',
        },
        {
          fieldName: 'file_lastName',
          name: 'Копия документа об изменении фамилии, имени, отчества (при наличии расхождений в вышеуказанных документах)',
        },
        {
          fieldName: 'file_insurance',
          name: 'Копия документа о страховом номере индивидуального лицевого счёта (копия СНИЛС)',
        },
        {
          fieldName: 'file_nostrification',
          name: 'Копия свидетельства о нострификации документов об образовании (для лиц, имеющих диплом иностранного государства)',
        },
      ],
      profs: [
        'Высшее образование',
        'Интернатура / ординатура',
        'Иное',
      ],
      medOrgs: [
        'ГКБ № 1 им. Н.И.Пирогова',
        'ГБУЗ "ГКБ № 4 ДЗМ"',
        'ГБУЗ "ГКБ № 13 ДЗМ"',
        'ГБУЗ "ГКБ № 15 ДЗМ"',
        'ГБУЗ "ГКБ № 17 ДЗМ"',
        'ГБУЗ "ГКБ № 24 ДЗМ"',
        'ГБУЗ "ГКБ № 29 им. Н.Э. Баумана"',
        'ГБУЗ "ГКБ № 31 ДЗМ"',
        'ГБУЗ "ММКЦ "Коммунарка" ДЗМ"',
        'ГБУЗ "ГКБ № 51 ДЗМ"',
        'ГБУЗ "ГКБ № 52 ДЗМ"',
        'ГКБ № 67',
        'ГБУЗ "ГКБ им. братьев Бахрушиных ДЗМ"',
        'ГБУЗ ГКБ им. С.П. Боткина ДЗМ',
        'ГБУЗ "ГКБ имени В.М. Буянова ДЗМ"',
        'ГБУЗ "ГКБ им. В.В. Вересаева ДЗМ"',
        'ГБУЗ "ГКБ им. В.В. Виноградова ДЗМ"',
        'ГБУЗ "Вороновская больница ДЗМ"',
        'ГБУЗ "ГКБ им. И.В. Давыдовского ДЗМ"',
        'ГБУЗ "ГКБ имени В.П. Демихова ДЗМ"',
        'ГБУЗ "ГКБ им. А.К. Ерамишанцева ДЗМ"',
        'ГБУЗ "ГКБ им. М.Е. Жадкевича ДЗМ"',
        'ГБУЗ "ГКБ им. Ф.И. Иноземцева ДЗМ"',
        'ГБУЗ "ГКБ им. М.П. Кончаловского ДЗМ"',
        'ГБУЗ "Больница "Кузнечики" ДЗМ"',
        'ГБУЗ "ГКБ им. Е.О. Мухина ДЗМ"',
        'ГБУЗ "ГКБ им. Д.Д. Плетнёва ДЗМ"',
        'ГБУЗ НИКИО им. Л.И. Свержевского ДЗМ',
        'ГБУЗ "ГКБ им. С.И. Спасокукоцкого ДЗМ"',
        'ГБУЗ "ГКБ им. С.С. Юдина ДЗМ"',
        'НИИ НДХиТ',
        'ГБУЗ "НИИ СП им. Н.В. Склифосовского ДЗМ"',
        'ГБУЗ "ТГБ ДЗМ"',
        'ГБУЗ "ЩГБ ДЗМ"',
        'ГБУЗ "Эндокринологический диспансер ДЗМ"',
        'ГБУЗ "Центр паллиативной помощи ДЗМ"',
        'ГБУЗ "Городская больница г.Московский ДЗМ"',
        'ГБУЗ "ГВВ № 1 ДЗМ"',
        'ГБУЗ "ГВВ № 2 ДЗМ"',
        'ГБУЗ "ГВВ № 3 ДЗМ"',
        'ГБУЗ "ИКБ № 1 ДЗМ"',
        'ГБУЗ ИКБ № 2 ДЗМ',
        'ГБУЗ "ТБ им. А.Е.Рабухина ДЗМ"',
        'ГБУЗ "ТКБ № 3 ДЗМ"',
        'ГБУЗ "ДГКБ им.З.А. Башляевой ДЗМ"',
        'ГБУЗ "Морозовская ДГКБ ДЗМ"',
        'ГБУЗ "ДГКБ св. Владимира ДЗМ"',
        'ГБУЗ "ДГКБ им. Н.Ф. Филатова ДЗМ"',
        'ГБУЗ "ДИКБ № 6 ДЗМ"',
        'ГБУЗ "ДГКБ № 9 им. Г.Н. Сперанского ДЗМ"',
        'ГБУЗ "ГКОБ № 1 ДЗМ"',
        'ГБУЗ "МГОБ № 62 ДЗМ"',
        'ГБУЗ "ПКБ № 1 ДЗМ"',
        'ГБУЗ "ПКБ № 4 ДЗМ"',
        'ГБУЗ "ПКБ № 5 ДЗМ"',
        'ГБУЗ "ПКБ № 13 ДЗМ"',
        'ГБУЗ "ПНД № 22 ДЗМ"',
        'ГАУЗ МНПЦ МРВСМ ДЗМ',
        'ГАУЗ "ЦВРТ ДЗМ"',
        'ГБУЗ "МНПЦ борьбы с туберкулезом ДЗМ"',
        'ГБУЗ "МГЦРБ ДЗМ"',
        'ГБУЗ МКНЦ имени А.С. Логинова ДЗМ',
        'ГБУЗ "Московский Центр дерматовенерологии и косметологии"',
        'ГБУЗ "МНПЦ наркологии ДЗМ"',
        'ГБУЗ НПЦ им. Соловьева ДЗМ',
        'ГБУЗ "НПЦ ДП ДЗМ"',
        'ГБУЗ "НПЦ ПЗДП им. Г.Е. Сухаревой ДЗМ"',
        'ГБУЗ "НПЦ спец.мед.помощи детям ДЗМ"',
        'ГБУЗ "ЦМП ДЗМ"',
        'ГБУЗ ЦПСиР ДЗМ',
        'ГБУЗ "ЧЛГ для ВВ ДЗМ"',
        'ГБУЗ особого типа "МТНПЦМК (ЦЭМП) ДЗМ"',
        'ГБУЗ "ДКЦ № 1 ДЗМ"',
        'ГБУЗ "КДЦ № 2 ДЗМ"',
        'ГБУЗ "ДЦ № 3 ДЗМ"',
        'ГБУЗ "КДЦ № 4 ДЗМ"',
        'ГБУЗ "ДЦ № 5 ДЗМ"',
        'ГБУЗ "КДЦ № 6 ДЗМ"',
        'ГБУЗ "ДЦЛИ ДЗМ"',
        'ГБУЗ "КДП № 121 ДЗМ"',
        'ГБУЗ "ГП № 2 ДЗМ"',
        'ГБУЗ "ГП № 3 ДЗМ"',
        'ГБУЗ "ГП № 5 ДЗМ"',
        'ГБУЗ "ГП № 6 ДЗМ"',
        'ГБУЗ "ГП № 8 ДЗМ"',
        'ГБУЗ "ГП № 9 ДЗМ"',
        'ГБУЗ "ГП № 11 ДЗМ"',
        'ГБУЗ "ГП № 12 ДЗМ"',
        'ГБУЗ "ГП № 19 ДЗМ"',
        'ГБУЗ "ГП № 22 ДЗМ"',
        'ГБУЗ "ГП № 23 ДЗМ"',
        'ГБУЗ "ГП № 36 ДЗМ"',
        'ГБУЗ "ГП № 45 ДЗМ"',
        'ГБУЗ "ГП № 46 ДЗМ"',
        'ГБУЗ "ГП № 52 ДЗМ"',
        'ГБУЗ "ГП № 62 ДЗМ"',
        'ГБУЗ "ГП № 64 ДЗМ"',
        'ГБУЗ "ГП № 66 ДЗМ"',
        'ГБУЗ "ГП № 67 ДЗМ"',
        'ГБУЗ "ГП № 68 ДЗМ"',
        'ГБУЗ "ГП № 69 ДЗМ"',
        'ГБУЗ "ГП № 107 ДЗМ"',
        'ГБУЗ "ГП № 109 ДЗМ"',
        'ГБУЗ "ГП № 115 ДЗМ"',
        'ГБУЗ "ГП № 134 ДЗМ"',
        'ГБУЗ "ГП № 166 ДЗМ"',
        'ГБУЗ г. Москвы ГП № 170 ДЗМ',
        'ГБУЗ "ГП № 175 ДЗМ"',
        'ГБУ ГП № 180 ДЗМ',
        'ГБУЗ "ГП № 191 ДЗМ"',
        'ГБУЗ "ГП № 195 ДЗМ"',
        'ГБУЗ "ГП № 209 ДЗМ"',
        'ГБУЗ "ГП № 210 ДЗМ"',
        'ГБУЗ "ГП № 212 ДЗМ"',
        'ГБУЗ "ГП № 214 ДЗМ"',
        'ГБУЗ "ГП № 218 ДЗМ"',
        'ГБУЗ "ГП № 219 ДЗМ"',
        'ГБУЗ "ГП № 220 ДЗМ"',
        'ГБУЗ "ДГП № 7 ДЗМ"',
        'ГБУЗ "ДГП № 10 ДЗМ"',
        'ГБУЗ "ДГП № 11 ДЗМ"',
        'ГБУЗ "ДГП № 12 ДЗМ"',
        'ГБУЗ "ДГП № 15 ДЗМ"',
        'ГБУЗ "ДГП № 23 ДЗМ"',
        'ГБУЗ "ДГП № 28 ДЗМ"',
        'ГБУЗ "ДГП № 30 ДЗМ"',
        'ГБУЗ "ДГП № 32 ДЗМ"',
        'ГБУЗ "ДГП № 38 ДЗМ"',
        'ГБУЗ "ДГП № 39 ДЗМ"',
        'ГБУЗ "ДГП № 42 ДЗМ"',
        'ГБУЗ "ДГП № 48 ДЗМ"',
        'ГБУЗ "ДГП № 52 ДЗМ"',
        'ГБУЗ "ДГП № 58 ДЗМ"',
        'ГБУЗ "ДГП № 61 ДЗМ"',
        'ГБУЗ "ДГП № 69 ДЗМ"',
        'ГБУЗ "ДГП № 81 ДЗМ"',
        'ГБУЗ "ДГП № 86 ДЗМ"',
        'ГБУЗ "ДГП № 91 ДЗМ"',
        'ГБУЗ "ДГП № 94 ДЗМ"',
        'ГБУЗ "ДГП № 98 ДЗМ"',
        'ГБУЗ "ДГП № 99 ДЗМ"',
        'ГБУЗ "ДГП № 104 ДЗМ"',
        'ГБУЗ "ДГП № 105 ДЗМ"',
        'ГБУЗ "ДГП № 110 ДЗМ"',
        'ГБУЗ "ДГП № 118 ДЗМ"',
        'ГБУЗ "ДГП № 120 ДЗМ"',
        'ГБУЗ "ДГП № 122 ДЗМ"',
        'ГБУЗ "ДГП № 125 ДЗМ"',
        'ГБУЗ "ДГП № 129 ДЗМ"',
        'ГБУЗ "ДГП № 130 ДЗМ"',
        'ГБУЗ "ДГП № 131 ДЗМ"',
        'ГБУЗ "ДГП № 132 ДЗМ"',
        'ГБУЗ "ДГП № 133 ДЗМ"',
        'ГБУЗ "ДГП № 140 ДЗМ"',
        'ГБУЗ "ДГП № 143 ДЗМ"',
        'ГБУЗ "ДГП № 145 ДЗМ"',
        'ГБУЗ "ДГП № 148 ДЗМ"',
        'ГБУЗ "ДГП № 150 ДЗМ"',
        'ГАУЗ "СП № 2 ДЗМ"',
        'ГАУЗ "СП № 3 ДЗМ"',
        'ГАУЗ "СП № 4 ДЗМ"',
        'ГАУЗ "СП № 5 ДЗМ"',
        'ГАУЗ "СП № 7 ДЗМ"',
        'ГАУЗ "СП № 8 ДЗМ"',
        'ГАУЗ "СП № 9 ДЗМ"',
        'ГАУЗ "СП № 11 ДЗМ"',
        'ГАУЗ "СП № 12 ДЗМ"',
        'ГАУЗ "СП № 13 ДЗМ"',
        'ГАУЗ "СП № 14 ДЗМ"',
        'ГАУЗ "СП № 15 ДЗМ"',
        'ГАУЗ "СП № 19 ДЗМ"',
        'ГАУЗ "СП № 22 ДЗМ"',
        'ГАУЗ "СП № 23 ДЗМ"',
        'ГАУЗ "СП № 24 ДЗМ"',
        'ГАУЗ "СП № 27 ДЗМ"',
        'ГАУЗ "СП № 32 ДЗМ"',
        'ГАУЗ "СП № 33 ДЗМ"',
        'ГАУЗ "СП № 34 ДЗМ"',
        'ГАУЗ "СП № 35 ДЗМ"',
        'ГАУЗ "СП № 48 ДЗМ"',
        'ГАУЗ "СП № 49 ДЗМ"',
        'ГАУЗ "СП № 50 ДЗМ"',
        'ГАУЗ "СП № 51 ДЗМ"',
        'ГАУЗ "СП № 53 ДЗМ"',
        'ГАУЗ "СП № 56 ДЗМ"',
        'ГАУЗ "СП № 60 ДЗМ"',
        'ГАУЗ "СП № 61 ДЗМ"',
        'ГАУЗ "СП № 62 ДЗМ"',
        'ГАУЗ "СП № 64 ДЗМ"',
        'ГАУЗ "СП № 65 ДЗМ"',
        'ГАУЗ "СП № 66 ДЗМ"',
        'ГАУЗ "СП № 67 ДЗМ"',
        'ГБУЗ "ДСП № 1 ДЗМ"',
        'ГБУЗ "ДСП № 6 ДЗМ"',
        'ГБУЗ "ДСП № 10 ДЗМ"',
        'ГБУЗ "ДСП № 16 ДЗМ"',
        'ГБУЗ "ДСП № 21 ДЗМ"',
        'ГБУЗ "ДСП № 25 ДЗМ"',
        'ГБУЗ "ДСП № 26 ДЗМ"',
        'ГБУЗ "ДСП № 28 ДЗМ"',
        'ГБУЗ "ДСП № 29 ДЗМ"',
        'ГБУЗ "ДСП № 30 ДЗМ"',
        'ГБУЗ "ДСП № 37 ДЗМ"',
        'ГБУЗ "ДСП № 38 ДЗМ"',
        'ГБУЗ "ДСП № 39 ДЗМ"',
        'ГБУЗ "ДСП № 41 ДЗМ"',
        'ГБУЗ "ДСП № 43 ДЗМ"',
        'ГБУЗ "ДСП № 44 ДЗМ"',
        'ГБУЗ "ДСП № 45 ДЗМ"',
        'ГБУЗ "ДСП № 46 ДЗМ"',
        'ГБУЗ "ДСП № 47 ДЗМ"',
        'ГБУЗ "ДСП № 52 ДЗМ"',
        'ГБУЗ "ДСП № 54 ДЗМ"',
        'ГБУЗ "ДСП № 58 ДЗМ"',
        'ГБУЗ "ДСП № 59 ДЗМ"',
        'ГБУЗ "ДСП № 63 ДЗМ"',
        'ГБУЗ "НПКЦ ДиТ ДЗМЦ"',
      ],
    }
  },
  validations() {
    return {
      userFields: {
        lastName: {
          required
        },
        firstName: {
          required
        },
        birthdayDate: {
          required
        },
        email: {
          required, email
        },
        phone: {
          required
        },
        placeOfWork: {
          required
        },
        speciality: {
          required
        },
        education: {
          required
        },
        specialisation: {
          required
        },
        position: {
          required
        },
        snils: {
          required
        },
        certificate: {
          required
        },
      },
    }
  },
  methods: {
    changeMultiselect(e,fieldName){
      this.$store.state.user.userFields[fieldName] = e;
    },
    async updateUser(){
      this.$Progress.start();
      const payLoad = {...this.userFields};
      const result = await this.$store.dispatch('user/updateUserFields', payLoad);
      if (result.success){
        this.notificationOpened = true;
        this.$store.dispatch('user/fetchUserFields');
      }
      await this.updateUserDocuments();
      this.$Progress.finish();
      return false;
    },
    async updateUserDocuments(){
     this.$Progress.start();
     const formDataIsEmpty = this.formData.entries().next().done;
     if (formDataIsEmpty) {
       this.$Progress.finish();
       return true;
     }
     const result = await this.$store.dispatch('user/updateUserDocuments', {
       formData: this.formData,
       callBack: (percent) => {this.$Progress.set(percent)},
     });

     if (result.success){
       this.formData = new FormData();
       this.notificationOpened = true;
       this.avatarPreview = false;
       this.$store.dispatch('user/fetchUserFields');
       this.$Progress.finish();
       return true;
     } else {
       this.$Progress.fail();
     }

    },
    async updateFileField(evt, filedName) {
      this.formFieldsMap.map(e => {
        if (e.fieldName === filedName) {
          e.isUploaded = true;
        }
      });

      if (evt.target.files.length) {
        let fileObj = evt.target.files[0];
        if (this.formData.has(filedName))  this.formData.delete(filedName);
        this.formData.append(filedName, fileObj);
      }

      if (filedName === 'file_logo') {
        this.$Progress.start();
        //this.$Progress.set(99)
        this.avatarPreview = URL.createObjectURL(evt.target.files[0]);
        const result = await this.$store.dispatch('user/updateUserDocuments', {
          formData: this.formData,
          callBack: (percent) => {this.$Progress.set(+percent)},
        });
        if (result.success){
          this.$Progress.finish();
          this.formData.delete('file_logo');
          this.notificationOpened = true;
        }
      }
    },
    async removeFile(fileTag){
      const fieldName = fileTag.fieldName;
      const formData = new FormData();
      if (formData.has(fieldName)) formData.delete(fieldName);
      formData.append(fieldName, '');
      this.$Progress.start();
      const result = await this.$store.dispatch('user/updateUserDocuments', {
        formData: formData,
        callBack: (val) => {this.progressBar = val},
      });
      if (result.success){
        this.$Progress.finish();
        this.formFieldsMap.map(f => {
          if (f.fieldName === fieldName){
            f.isUploaded = false;
            delete f.data;
          }
        })
      } else {
        this.$Progress.fail();
      }
    }
  },
}
</script>

<style scoped>
  .cabinet {
    margin-bottom: 0!important;
    padding-bottom: 77px;
  }
  .cabinet__label--unverified {
    color: #fff;
  }

  .form--cabinet .form__input[disabled] {
    background-color: #ededed;
  }

  .green {
    color: #27AE60;
  }

  .red {
    color: darkred;
  }

  .cabinet__download {
    padding-left: 44px;
  }

  .cabinet__download--text::before {
    background-image: url(@/assets/img/icons/icon-file-text.svg);
  }
  .cabinet__download--img::before {
    background-image: url(@/assets/img/icons/icon-file-img.svg);
  }

</style>