<template>
    <div class="presentations-page" id="presentations" data-nav-section>
        <div class="presentations-page__wrap">
            <div class="wrapper">
                <div class="presentations-page__head">
                    <h1 class="presentations-page__heading">Презентации лекторов по прошедшим мероприятиям</h1>
                </div>


                <ul class="presentations-page__list">
                    <li v-for="presentation, index in presentations" :key="presentation.id" class="presentations-page__item">
                        <a target="_blank" rel="noopener nofollow" :href="presentation.file_id.urlLink" class="presentations-page__link">
                            {{index + 1}}. {{ presentation.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';


const store = useStore();

const presentations = computed(() => {
    return store.state.events.presentations
})

</script>

<style lang="scss" scoped>
.presentations-page {
    &__wrap {
        padding: 100px 0;

        @media (max-width: 600px) {
            padding: 40px 0;
        }
    }

    &__heading {
        margin-bottom: 0;
        font-size: 30px;

        @media (max-width: 768px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }

    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 30px;
        }
    }

    &__list {
        margin: 0;
        list-style: none;
        padding: 0;
        display: grid;
        gap: 20px;
    }

    &__link {
        font-size: 24px;
        text-decoration: none;
    }
}
</style>