import axios from "axios";

axios.defaults.baseURL = 'https://api.directual.com/good/api/v5/data/';
const appId = '14411970-78ad-47b2-a55d-af4851f9ada2';

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async sendReview({rootGetters}, payload){
      try {
        const token = rootGetters["user/getAuthToken"];
        const response = await axios.post(`/educationFeedback/educationFeedback?appID=${appId}&sessionID=${token}`, payload);
        if (response.data && response.data.result[0]) {
          return {success: true};
        }
        return {success: false};
      } catch (e){
        console.log(e);
      }
    },
  },
}