<template>
    <div class="in-progress">
        <div class="in-progress__wrap">
            <div class="in-progress__head">Этот раздел заполняется. <br /> Скоро тут будет интересно</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.in-progress {

&__wrap {
    min-height: 50vh;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: url(@/assets/img/images/bg-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    border-radius: 30px;
}

&__head {
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #333333;
}

@media (max-width: 728px) {
    &__head {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    &__head {
        font-size: 16px;
    }
}

}
</style>