<template>
    <div class="topics-offer">
        <div class="topics-offer__wrap">
            <div class="topics-offer__content">
                <div class="topics-offer__head">
                    <div class="topics-offer__title">Предложите свою тему или спикера</div>
                </div>
                <form class="topics-offer__form">
                    <textarea :disabled="justSuggested" v-model="suggestionText"
                        class="topics-offer__control" placeholder="Напишите название темы или фио спикера" />

                    <div class="topics-offer__foot">
                        <router-link v-if="!userIsAuthorized" :to="`/login`" :class="`topics-offer__btn`">войти и отправить
                        </router-link>

                        <a v-else-if="!userIsVerified" class="topics-offer__btn"><span>Отправить</span></a>
                        <a v-else-if="!justSuggested" @click="sendSuggestion" class="topics-offer__btn"
                            :class="{ 'link--unverified': !userIsVerified }"><span>Отправить</span></a>
                        <span v-else class="topics-offer__btn topics-offer__btn--disabled">спасибо за ваше предложение</span>
                        <!-- <p v-if="(justSuggested || alreadyVoted) && daysToEnd" class="topics-offer__date">Следующий выбор можно
                            <br>будет сделать {{ daysToEnd }}</p> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import moment from "moment-with-locales-es6";

export default {
    name: "topicSuggestion",
    emits: ['vote'],
    created() {
    },
    computed: {
        alreadyVoted() {
            const votedIds = this.$store.state.topicSuggestion.suggestedEducationTheme_ids;
            const userId = this.$store.state.user.educationUser_id;
            if (votedIds && userId) {
                return votedIds.some(e => e.author_id === userId);
            }
            return false
        },
        userIsAuthorized() {
            return this.$store.state.user.authorized || false
        },
        userIsVerified() {
            return this.$store.state.user.userFields.isVerified || false
        },
        daysToEnd() {
            const dateToCheck = this.$store.state.topicSuggestion.dateEnd;
            if (!dateToCheck) return false;
            if (+dateToCheck < Date.now()) return false;
            return moment(new Date(dateToCheck).setHours(0, 0, 0, 0)).locale('ru').from(new Date().setHours(0, 0, 0, 0));
        },
    },
    data() {
        return {
            suggestionText: '',
            educationUserId: this.$store.state.user.educationUser_id || null,
            justSuggested: false,
        }
    },
    methods: {
        async sendSuggestion() {
            if (!this.userIsVerified) return;
            //const payload = this.prepareVotePayload(this.educationUserId, this.chosenTopicIds);
            const text = this.suggestionText;
            const result = await this.$store.dispatch('topicSuggestion/suggestTopic', text);
            if (result.success) {
                this.justSuggested = true;
                this.$emit('vote', `
            <div class="popup__wrap popup__center">
              <p class="popup__title">Спасибо за ваше предложение</p>
              <p @clicksomething">Мы обработаем все предложения и наиболее востребованные темы появятся <br> в разделе «Ближайшие мероприятия»</p>
            </div>
            `);

                setTimeout(() => {
                    this.justSuggested = false
                }, 2000);
            }
        }
    },

}
</script>
  
<style lang="scss" scoped>
.topics-offer {

    &__content {
        background-color: #fff;
        padding: 50px 60px;
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.11);
        border-radius: 30px;

        @media (max-width: 991px) {
            width: 95%;
            margin: auto;
            padding: 20px;
        }
    }

    &__control {
        border: 1px solid rgba(219, 219, 219, 0.5);
        border-radius: 10px;
        padding: 30px;
        resize: none;
        width: 100%;
        height: 200px;
        text-align: left;

        @media (max-width: 768px) {
            padding: 20px;
        }

        &::placeholder {
            color: #c2c2c2;
        }
    }

    &__wrap {
        border-radius: 30px;
        overflow: auto;
    }

    &__head {
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 991px) {
            margin-bottom: 32px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__title {
        font-size: 30px;
        line-height: 110%;
        margin-top: 0;

        @media (max-width: 991px) {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }

    &__foot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        @media (max-width: 768px) {
            margin-top: 24px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1274E0;
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        max-width: 300px;
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        letter-spacing: 0.02em;
        margin: auto;

        @media (max-width: 768px) {
            max-width: 100%;
        }

        &--inactive {
            background: transparent;
            color: #1274E0;
        }

    }

    &__date {
        font-size: 16px;

        @media (max-width: 768px) {
            text-align: center;
            font-size: 14px;
            width: 100%;
        }
    }
}

</style>