<template>
    <div class="material__wrap" v-if="preparedSop">
        <div class="material__list-wrap">
            <MediaItem class="material__media-item material__media-item--sticky" :sticked="true" :item="sop" :key="sop.name"
                v-for="sop in sticked" />
            <swiper :slides-per-view="'auto'" :space-between="20"
                :navigation="{ nextEl: '.material__next--01', prevEl: '.material__prev--01', }"
                :class="`swiper-container material__slider material__slider--01`" @swiper="onSwiper"
                @slideChange="onSlideChange">
                <swiper-slide v-for="(sop, idx) in preparedSop" :key="idx" class="material__slide">
                    <MediaItem :sticked="sop.name.toLowerCase().includes('методические рекомендации по разработке макета')"
                        class="material__media-item" :item="sop" />
                </swiper-slide>
                <template v-if="preparedSop.length > 2">
                    <div class="swiper-button-prev material__prev"
                        :class="swiper?.realIndex === 0 && 'material__prev--disabled'" @click="swiper.slidePrev()"></div>
                    <div class="swiper-button-next material__next"
                        :class="swiper?.realIndex + 3 == swiper?.slides.length && 'material__next--disabled'"
                        @click="swiper.slideNext()">
                    </div>
                </template>
            </swiper>
        </div>

        <RouterLink class="material__link" to="/sop">Смотреть все</RouterLink>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import MediaItem from '../MediaItem.vue';
//import { useSwiper } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
export default {
    name: "sopList",
    components: {
        Swiper,
        SwiperSlide,
        MediaItem
        //useSwiper
    },
    setup() {
        const onSlideChange = () => { };
        return { onSlideChange, };
    },
    computed: {
        userIsAllowed() {
            const $store = this.$store;
            return ($store.state.user.authorized
                && $store.state.user.userFields?.isVerified
                && ($store.state.user.userFields?.status === 'Верифицирован из списка'
                    || $store.state.user.userFields?.status === 'Верифицирован')
            )
        },
        sticked() {
            let result = this.$store.state.materials.sop;

            return result.filter(el => el.name.toLowerCase().includes('методические рекомендации по разработке макета'));
        },
        preparedSop() {
            let result = this.$store.state.materials.sop
            result = result.sort((a, b) => b.createDate - a.createDate);

            return window.matchMedia('(max-width: 768px)').matches
                ? result.sort((a) => a.name.toLowerCase().includes('методические рекомендации по разработке макета') ? -1 : 1)
                : result.filter(el => !el.name.toLowerCase().includes('методические рекомендации по разработке макета'));
        },
        userIsAuthorized() {
            return this.$store.state.user.authorized || false
        },
        userIsVerified() {
            return this.$store.state.user.userFields.isVerified || false
        },
    },
    data() {
        return {
            swiper: null,
            signedIds: []
        };
    },
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },
    }
}
</script>

<style lang="scss" scoped>
.material {
    &__wrap {
        margin-bottom: 30px;
    }

    &__list-wrap {
        position: relative;
        display: flex;
        gap: 20px;

        @media (max-width: 768px) {
            display: block;
        }
    }

    &__slider {
        clip-path: inset( 0vw -50vw 0vw -10vw );
        width: 70%;
        flex: 1 1 auto;

        @media (max-width: 768px) {
            clip-path: none;
            width: 100%;
        }
    }

    &__slide,
    &__slider {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: visible;
    }

    &__slide {
        width: 320px;
        color: #fff;
        border-radius: 20px;
        overflow: hidden;
        -webkit-transition: ease-out 0.3s;
        transition: ease-out 0.3s;
        filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.05));
    }

    &__prev,
    &__next {
        width: 66px;
        height: 66px;
        bottom: 0;
        top: 0;
        margin: auto;
        background-image: url(@/assets/img/icons/next.svg);
        background-position: center;
        background-size: 66px;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-color: rgba(51, 51, 51, 0.6);

        &--disabled {
            opacity: 0;
            transition: 500ms;
        }

        &::before,
        &::after {
            content: none;
        }
    }

    &__prev {
        left: -1vw;
        transform: rotate(180deg);

        @media (max-width: 1500px) {
            left: -1.5vw;
        }
    }

    &__next {
        right: -15vw;

        @media (max-width: 1700px) {
            right: -10vw;
        }

        @media (max-width: 1500px) {
            right: 0;
        }
    }

    

    &__link {
        background: #1274E0;
        border-radius: 50px;
        width: 100%;
        max-width: 195px;
        padding: 12px 24px;
        height: auto;
        display: flex;
        color: #fff;
        justify-content: center;
        text-align: center;
        margin: auto;
        margin-top: 60px;
        font-size: 16px;
        text-decoration: none;
    }

    &__media-item {
        &--sticky {
            flex: 0 0 auto;
            width: 320px;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}
</style>