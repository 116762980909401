import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "@/views/HomeView";
import RegisterView from "@/views/RegisterView";
import LoginView from "@/views/LoginView";
import RemindingView from "@/views/RemindingView";
import ProfileView from "@/views/ProfileView";
import PasswordView from "@/views/PasswordView";
import PersonalDataView from "@/views/PersonalDataView";
import PolicyView from "@/views/PolicyView";
import NotFound from "@/views/NotFound";
import VideoPage from "@/views/VideoPage";
import EventsViews from "@/views/EventsView";
import SopView from "@/views/SopView";
import InstructionsView from "@/views/InstructionsView";
import DocumentView from '@/views/DocumentView';
import MediaView from '@/views/MediaView';
import SingleMediaView from '@/views/SingleMediaView';
import SingleAnnounceView from '@/views/SingleAnnounceView';
import SinglePhotoView from '@/views/SinglePhotoView';
import FilePreview from '@/views/FilePreviewView'
import Presentations from '@/views/Presentations'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      title: 'Medtech',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      mainClass: 'main-white',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Medtech',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      mainClass: 'main-white',
    },
  },
  {
    path: '/reminding',
    name: 'reminding',
    component: RemindingView,
    meta: {
      title: 'Medtech',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      mainClass: 'main-white',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: true,
      needAuth: true,
      mainClass: 'main-white',
    },
  },
  {
    path: '/profile/password',
    name: 'profilePassword',
    component: PasswordView,
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: true,
      needAuth: true,
      mainClass: 'main-white',
    },
  },
  {
    path: '/personaldata',
    name: 'personalData',
    component: PersonalDataView,
    meta: {
      title: 'Medtech',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      mainClass: 'main-white',
    },
  },
  {
    path: '/policy',
    name: 'policy',
    component: PolicyView,
    meta: {
      title: 'Medtech',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      mainClass: 'main-white',
    },
  },
  {
    path: '/video/:id',
    name: 'video',
    component: VideoPage,
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-white',
    },
  },
  {
    path: '/events',
    name: 'events',
    component: EventsViews,
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-gray',
    },
  },
  {
    path: '/sop',
    name: 'sop',
    component: SopView,
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-gray',
    },
  },
  {
    path: '/instruction/:id', 
    component: InstructionsView, 
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-gray',
    },
  },
  {
    path: '/instruction/:id/:articleID', 
    component: DocumentView, 
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-gray',
    },
  },
  {
    path: '/media', 
    component: MediaView, 
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-gray',
    },
  },
  {
    path: '/media/:id', 
    component: SingleMediaView, 
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-gray',
    },
  },
  {
    path: '/announce/:id', 
    component: SingleAnnounceView, 
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-gray',
    },
  },
  {
    path: '/photo/:id', 
    component: SinglePhotoView, 
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-gray',
    },
  },
  {
    path: '/preview/:url(.*)*',
    name: 'preview',
    component: FilePreview,
    meta: {
      title: 'Medtech',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      mainClass: 'not-gray',
    },
  },
  {
    path: '/presentations',
    name: 'presentations',
    component: Presentations,
    meta: {
      title: 'Medtech',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      mainClass: 'main-gray',
    },
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      title: 'Medtech',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      mainClass: 'not-found',
    },
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/404' },
]

import store from "@/store/index"

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (to.meta.needAuth && !store.state.user.authorized) {
    return { name: 'login' }
  }
})

const DEFAULT_TITLE = 'Medtech Moscow';
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router
