<template>
    <div class="announce-page" id="announces" data-nav-section>
        <div class="announce-page__wrap">
            <div class="wrapper">
                <nav class="announce-page__breadcrumbs">
                    <router-link to="/#announces" class="breadcrumbs__link">Анонсы</router-link>
                    <span class="breadcrumbs__link">{{ currentAnnounce?.name }}</span>
                </nav>

                <div class="announce-page__content">
                    <div class="announce-page__date" v-if="currentAnnounce.createDate">
                        {{ getFormattedDate(currentAnnounce.createDate) }}
                    </div>

                    <div ref="articleWrap" class="announce-page__article" v-html="currentAnnounce.description"></div>
                </div>
            </div>

            <ArticleAttachments class="announce-page__article-attachments" v-if="currentAnnounce.files_ids?.length"
                :attachments="currentAnnounce.files_ids" />

        </div>

        <nav class="announce-page__breadcrumbs article-page__breadcrumbs--bottom">
            <div class="wrapper">
                <router-link to="/#announces" class="breadcrumbs__link">Анонсы</router-link>
                <span class="breadcrumbs__link">{{ currentAnnounce?.name }}</span>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref } from 'vue';

import ArticleAttachments from '@/components/Article/Attachments'
import moment from 'moment-with-locales-es6';

const store = useStore()
const route = useRoute()
const router = useRouter()
const { id } = route.params

const articleWrap = ref()

const currentAnnounce = computed(() => {
    let result = false

    result = store.getters['materials/getAnnouncesFromCacheById'](id)

    return result
})

const getFormattedDate = (date) => {
    moment.locale('ru')
    const momentDate = moment(date).clone();

    return momentDate.format('D MMMM YYYY года')
}

if (!currentAnnounce.value) {
    router.push('/')
}

</script>

<style lang="scss" scoped>
.announce-page {
    &__wrap {
        padding: 100px 0;

        @media (max-width: 600px) {
            padding: 40px 0;
        }
    }

    &__img {
        max-height: 60vh;
        margin: auto;
        display: block;
        margin-bottom: 20px;
    }

    &__breadcrumbs {
        margin-bottom: 60px;

        &--bottom {
            padding: 20px 0;
            border-top: 1px solid rgba(#16171E, 0.2);
            border-bottom: 1px solid rgba(#16171E, 0.2);
            margin-bottom: 0;
        }
    }


    &__nav {
        background: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        position: sticky;
        top: 20px;

        &-title {
            font-size: 18px;
            padding: 16px 24px;
        }

        &-list {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        &-link {
            display: flex;
            text-decoration: none;
            padding: 20px 25px;
            font-size: 12px;
            line-height: 150%;
            border-left: 3px solid transparent;
            transition: 500ms;

            &--active {
                background: rgba(18, 116, 224, 0.1);
                transition: 500ms;
                border-color: #1274E0;
                box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);

                @media (max-width: 991px) {
                    border-color: transparent;
                    background: transparent;
                    box-shadow: none;
                }
            }
        }
    }

    &__meta {
        display: flex;
        align-items: center;
        gap: 15px;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 30px;

        &-link {
            padding: 12px 20px;
            background: rgba(18, 116, 224, 0.1);
            border-radius: 5px;
            text-decoration: none;
            color: #1274E0;
            display: flex;
            align-items: center;
            gap: 5px;


            &::after {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' viewBox='0 0 5 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8L4 4.5L1 1' stroke='%231274E0' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                width: 10px;
                height: 10px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    &__article {
        font-size: 16px;
        margin-bottom: 100px;

        &:deep(h1) {
            font-size: 40px;
            line-height: 120%;
        }

        &:deep(h2) {
            font-size: 26px;
            line-height: 120%;
        }

        &:deep(iframe) {
            width: 100%;
            height: 600px;

            @media (max-width: 768px) {
                height: 300px;
            }

            @media (max-width: 400px) {
                height: 200px;
            }
        }
    }

    &__article-attachments {
        &:deep(.attachment-item__wrap) {
            background-color: #1274E0;
        }
    }
}
</style>