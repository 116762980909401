<template>
    <section class="article-publications">
        <div class="article-publications__wrap">
            <div class="wrapper">
                <h2 class="article-publications__heading">
                    {{title}}
                </h2>
                
                <ul class="article-publications__list">
                    <li class="article-publications__item" v-for="item in publications" :key="item.id">
                        <a target="_blank" rel="nofollow" :href="articleURL(item.url)" class="article-publications__item-title" v-if="item.url">
                            {{ item.name }}
                        </a>
                        <div class="article-publications__item-title" v-else>
                            {{ item.name }}
                        </div>
                        <div class="article-publications__item-author">
                            {{ item.description }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script setup>
defineProps(['publications', 'title'])

const articleURL = (url) => {
    if (url.includes('http')) return url

    return `http://${url}`
}
</script>

<style lang="scss" scoped>
.article-publications {
    &__wrap {

        background: rgba(214, 214, 214, 0.1);
        padding: 50px 0;
    }

    &__heading {
        font-size: 30px;
        margin-bottom: 50px;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        gap: 30px;
    }

    &__item {
        font-size: 16px;
        max-width: 840px;
        line-height: 160%;

        &-title {
            font-weight: 600;
        }
    }
}
</style>