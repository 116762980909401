<template>
    <div class="topics-selection">
        <div class="topics-selection__wrap">
            <div class="topics-selection__content">
                <div class="topics-selection__head">
                    <div class="topics-selection__title">Выберите темы для заседаний школы второго полугодия 2024 года</div>
                    <div class="topics-selection__count">
                        Выбрано: {{ chosenTopicIds.length }} {{ pluralize(chosenTopicIds.length, 'тема', 'темы', 'тем') }}
                    </div>
                </div>
                <ul class="topics-selection__list">
                    <li v-for="topic in topics" :key="topic.id" class="topics-selection__item" :class="{
                        selected: this.chosenTopicIds.includes(topic.id),
                    }" @click="toggleVotedTopic(topic.id)">
                        {{ topic.name }}
                    </li>
                </ul>
                <div class="topics-selection__foot">
                    <router-link v-if="!userIsAuthorized" :to="`/login`" :class="`topics-selection__btn`">Войти и выбрать
                        тему
                    </router-link>
                    <a v-else-if="!userIsVerified" class="topics-selection__btn"><span>Выбрать тему</span></a>
                    <a href="#" v-else-if="!justVoted" @click.prevent="vote" class="topics-selection__btn">
                        <span>выбрать тему</span>
                    </a>
                    <div v-else class="topics-selection__btn topics-selection__btn--inactive">Спасибо за ваш выбор</div>
                    <!-- <p v-if="alreadyVoted && daysToEnd" class="topics-selection__date">Следующий выбор можно <br>будет сделать
                        {{ daysToEnd }}
                    </p> -->
                    <!-- <p v-else class="topics-selection__date">Сбор заявок заканчивается {{ dateEnd }}</p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-with-locales-es6";
import plural from 'plural-ru'
//import PopupComponent from "@/components/popupComponent";
export default {
    name: "TopicsSelection",
    //components: {PopupComponent},
    emits: ['vote'],
    created() {
        this.$store.dispatch('topicSuggestion/fetchInit');
    },
    computed: {
        multiselectOptions() {
            const result = [];
            this.topics.forEach(t => {
                result.push({ id: t.id, name: t.name })
            });
            return result;
        },
        dateEnd() {
            return moment(this.$store.state.topicSuggestion.dateEnd).locale('ru').format('DD-MM-YYYY');
        },
        daysToEnd() {
            const dateToCheck = this.$store.state.topicSuggestion.dateEnd;
            if (!dateToCheck) return false;
            if (+dateToCheck < Date.now()) return false;
            return moment(new Date(dateToCheck).setHours(0, 0, 0, 0)).locale('ru').from(new Date().setHours(0, 0, 0, 0));
        },
        topics() {
            const topics = this.$store.state.topicSuggestion.educationTheme_ids;

            if (this.educationUserId) {
                topics.map(t => {
                    if (!t.educationThemeVotes_ids || !t.educationThemeVotes_ids.length) return;
                    t.educationThemeVotes_ids.some(v => {
                        t.votedByUser = false;
                        if (this.educationUserId === v.educationUser_id) {
                            //this.alreadyVoted = true;
                            t.votedByUser = true;
                            // if (!this.chosenTopicIds.includes(t.id)) {
                            //     this.chosenTopicIds.push(t.id);
                            //     this.selectedMobileMultiselect.push({
                            //         id: t.id,
                            //         name: t.name
                            //     })
                            // }
                        }
                    });
                });
            }
            console.log(topics)
            return topics;
        },
        userIsAuthorized() {
            return this.$store.state.user.authorized || false
        },
        userIsVerified() {
            return this.$store.state.user.userFields.isVerified || false
        },
    },
    data() {
        return {
            justVoted: false,
            popupOpened: false,
            alreadyVoted: false,
            educationUserId: this.$store.state.user.educationUser_id || null,
            chosenTopicIds: [],
            selectedMobileMultiselect: [],
        }
    },
    watch: {
        topics() {
            const topics = this.$store.state.topicSuggestion.educationTheme_ids;

            if (this.educationUserId) {
                topics.some(t => {
                    if (!t.educationThemeVotes_ids || !t.educationThemeVotes_ids.length) return false;
                    t.educationThemeVotes_ids.some(v => {
                        if (this.educationUserId === v.educationUser_id) {
                            if (!this.chosenTopicIds.includes(t.id)) {
                                this.chosenTopicIds.push(t.id);
                                this.selectedMobileMultiselect.push({
                                    id: t.id,
                                    name: t.name
                                })
                            }
                        }
                    });
                });
            }
        }
    },
    methods: {
        pluralize(count, text1, text2, text3) {
            return plural(count, text1, text2, text3)
        },
        toggleVotedTopic(topicId) {
            // if (this.alreadyVoted) return;
            this.chosenTopicIds = this.toggleArrayElement(this.chosenTopicIds, topicId)


        },
        async vote() {
            if (!this.userIsVerified) {

                this.$emit('vote', `
          <div class="modal__wrap">
          <figure class="modal__icon">
            <img src="/img/icons/tick.png" alt="Спасибо за ваш голос" width="132" height="98">
          </figure>
          <p class="modal__title">Спасибо за ваш выбор</p>
          <p>Скоро мы запланируем мероприятие на наиболее востребованную тему. Новое мероприятие появится в разделе «Ближайшие мероприятия»</p>
            <!--      <a href="#" @click.prevent="popupClose" class="modal__link">вернуться на сайт</a>-->
          </div>
          `);
                return;
            }

            const payload = this.prepareVotePayload(this.educationUserId, this.chosenTopicIds);

            console.log(payload)

            const result = await this.$store.dispatch('topicSuggestion/vote', payload);

            console.log(result)

            if (result.success) {
                this.justVoted = true;
                this.alreadyVoted = true;
                this.$emit('vote', `
              <div class="popup__wrap popup__center">
              <figure class="modal__icon">
                <img src="/img/icons/tick.png" alt="Спасибо за ваш голос" width="132" height="98">
              </figure>
              <p class="popup__title">Спасибо за ваш выбор</p>
              <p>Скоро мы запланируем мероприятие на наиболее востребованную тему. Новое мероприятие появится в разделе «Ближайшие мероприятия»</p>
                <!--      <a href="#" @click.prevent="popupClose" class="modal__link">вернуться на сайт</a>-->
              </div>
              `);

              setTimeout(() => {
                this.justVoted = false;
              }, 2000)
            }
        },
        prepareVotePayload(educationUserId, chosenTopicIds) {
            const baseThemes = this.$store.state.topicSuggestion.educationTheme_ids

            console.log(baseThemes)

            // Find unvoted themes (was checked before, unchecked by user)
            const unvotedThemes = baseThemes.filter(el => el.votedByUser).map((baseTheme) => {
                if (!chosenTopicIds.includes(baseTheme.id)) return baseTheme.id
            }).filter(el => Boolean(el));


            // Create payload for unvoted themes, must contain id of voice and isDeleted
            const deletePayload = unvotedThemes.map(unvotedThemeId => {

                return {
                    id: baseThemes.find(el => el.id === unvotedThemeId).educationThemeVotes_ids.find((vote) => vote.educationUser_id === this.educationUserId).id,
                    isDeleted: true
                }
            })

            let result = [];

            chosenTopicIds.forEach(id => result.push({ educationTheme_id: id, educationUser_id: educationUserId }))

            result = result.concat(deletePayload)

            return result;
        },
        // prepareVoteMobilePayload(educationUserId, selectedArray) {
        //     if (!selectedArray.length) return [];
        //     const result = [];
        //     selectedArray.forEach(arr => result.push({ educationTheme_id: arr.id, educationUser_id: educationUserId }));
        //     return result;
        // },
        toggleArrayElement(array, value) {
            //debugger;
            const index = array.indexOf(value);
            if (index === -1) {
                array.push(value);
            } else {
                array.splice(index, 1);
                //delete array[index];
            }
            return array;
        },
    },

}
</script>

<style lang="scss" scoped>
.topics-selection {

    &__content {
        background-color: #fff;
        padding: 50px 60px;
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.11);
        border-radius: 30px;

        @media (max-width: 991px) {
            width: 95%;
            margin: auto;
            padding: 20px;
        }
    }

    &__wrap {
        border-radius: 30px;
        overflow: auto;
    }

    &__head {
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 991px) {
            margin-bottom: 32px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__title {
        font-size: 30px;
        line-height: 110%;
        margin-top: 0;

        @media (max-width: 991px) {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }

    &__count {
        font-size: 16px;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        list-style: none;
        padding: 0;
        margin: 0;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }

    &__item {
        border: 1px solid rgba(219, 219, 219, 0.5);
        border-radius: 10px;
        padding: 20px 34px;
        padding-left: 60px;
        position: relative;
        transition: 500ms;
        font-size: 18px;
        font-weight: 400;
        line-height: 130%;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #010101;

        @media (max-width: 768px) {
            padding: 0;
            border: none;
            padding-left: 40px;
            font-size: 14px;
        }

        &::before {
            content: '';
            border: 1px solid #DCE2FB;
            border-radius: 5px;
            position: absolute;
            width: 26px;
            height: 26px;
            left: 20px;
            top: 0;
            bottom: 0;
            margin: auto;

            @media (max-width: 768px) {
                left: 0;
            }
        }

        &::after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6.9339L5.29294 10.4349L14.0996 1.56494' stroke='%231274E0' stroke-width='2'/%3E%3C/svg%3E%0A");
            width: 15px;
            height: 15px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            left: 26px;
            top: 0;
            bottom: 0;
            margin: auto;
            opacity: 0;
            transition: 500ms;

            @media (max-width: 768px) {
                left: 6px;
            }
        }

        &.selected {
            background: rgba(231, 232, 250, 0.1);
            color: #1274E0;
            border: 1px solid rgba(18, 116, 224, 0.05);
            transition: 500ms;

            &::after {
                opacity: 1;
                transition: 500ms;
            }
        }
    }

    &__foot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        @media (max-width: 768px) {
            margin-top: 24px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1274E0;
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        max-width: 300px;
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        letter-spacing: 0.02em;

        @media (max-width: 768px) {
            max-width: 100%;
        }

        &--inactive {
            background: transparent;
            color: #1274E0;
        }

    }

    &__date {
        font-size: 16px;

        @media (max-width: 768px) {
            text-align: center;
            font-size: 14px;
            width: 100%;
        }
    }
}
</style>