import axios from "axios";

axios.defaults.baseURL = 'https://api.directual.com/good/api/v5/data/';
const appId = '14411970-78ad-47b2-a55d-af4851f9ada2';

export default {
  namespaced: true,
  state: {
    events: [],
    gcpEvents: [],
    presentations: [],
  },
  getters: {
    getGcpEvents(state){
      return state.gcpEvents;
    },
    getEvents(state){
      return state.events;
    },
    getPresentations(state) {
      return state.presentations;
    },
    getGcpEventFromCacheById: () => (id) => {
      const cacheEvents = JSON.parse(getFromLocalStorage('gcpEvents'));
      return cacheEvents.find(e => e.id === id);
    },
    getEventFromCacheById: () => (id) => {
      const cacheEvents = JSON.parse(getFromLocalStorage('events'));
      return cacheEvents.find(e => e.id === id);
    },
    getPresentationFromCacheById: () => (id) => {
      const cachePresentations = JSON.parse(getFromLocalStorage('presentations'));
      return cachePresentations.find(e => e.id === id);
    },

  },
  mutations: {
  },
  actions: {
    async fetchEvents({state, rootGetters}) {
      if (state.events && state.events.length) return;
      const token = rootGetters["user/getAuthToken"];
      const response = await axios.get(`/educationEvents/getCurrentEducationEvents?appID=${appId}&sessionID=${token}&pageSize=1000`);

      if (response.data && response.data.payload) {
        state.events = response.data.payload;
        saveToLocalStorage('events', response.data.payload);
      }
    },
    async fetchGcpEvents({state, rootGetters}) {
      if (state.events && state.events.length) return;
      const token = rootGetters["user/getAuthToken"];
      const response = await axios.get(`/educationGCP/getCurrentEducationGCP?appID=${appId}&sessionID=${token}&isVisible=false&pageSize=1000`);
      if (response.data && response.data.payload) {
        state.gcpEvents = response.data.payload;
        saveToLocalStorage('gcpEvents', response.data.payload);
      }
    },
    async fetchPresentations({state, rootGetters}) {
      if (state.presentations && state.presentations.length) return;
      const token = rootGetters["user/getAuthToken"];
      const response = await axios.get(`/educationPresentation/getEducationPresentation?appID=${appId}&sessionID=${token}&pageSize=1000`);
      if (response.data && response.data.payload) {
        state.presentations = response.data.payload;
        saveToLocalStorage('presentations', response.data.payload);
      }
    },
    async signForEvent({state, rootGetters}, params){
      try {
        state;
        const token = rootGetters["user/getAuthToken"];
        const educationUser_id = rootGetters["user/getEducationUserId"];
        const payload = {
          "educationUser_id": educationUser_id,
          "educationEvent_id": params.eventId
        }
        if (params.isDeleted) {
          payload.isDeleted = true
        }
        const response = await axios.post(`/educationEventsParticipants/editEducationEventsParticipant?appID=${appId}&sessionID=${token}`, payload);
        if (response.data && response.data.result[0]) {
          return {success: true};
        }
        return {success: false};
      } catch (e){
        console.log(e);
      }
    },
    async signForGcpEvent({state, rootGetters}, params){
      try {
        state;
        const token = rootGetters["user/getAuthToken"];
        const educationUser_id = rootGetters["user/getEducationUserId"];
        const payload = {
          "educationUser_id": educationUser_id,
          "educationGCP_id": params.eventId
        }
        if (params.isDeleted) {
          payload.isDeleted = true
        }
        const response = await axios.post(`/educationGCPParticipants/editEducationGCPParticipant?appID=${appId}&sessionID=${token}`, payload);

        if (response.data?.status === 'success') {
          return {success: true};
        }

        if (response.data?.status === 'error') {
          return {
            error: true,
            alreadySigned: true
          };
        }
        
        return {success: false};
      } catch (e){
        console.log(e);
      }
    },
  },
}

function getFromLocalStorage(key){
  return localStorage.getItem(key);
}
function saveToLocalStorage(key, data){
  if (!key || !data) return;
  localStorage.setItem(key, JSON.stringify(data));
}