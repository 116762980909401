import { createStore } from 'vuex'
import init from "@/store/modules/init";
import user from "@/store/modules/user";
import topicSuggestion from "@/store/modules/topicSuggestion";
import events from "@/store/modules/events";
import materials from "@/store/modules/materials";
import review from "@/store/modules/review";
import educationSurvey from './modules/educationSurvey';

export default createStore({
  modules: {
    init,
    user,
    topicSuggestion,
    events,
    materials,
    review,
    educationSurvey
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },

})
