<template>
  <header v-if="!$route.meta.hideNav" class="header" :class="$route.meta.headerClass">
    <div class="wrapper">
      <button v-if="!$route.meta.shortHeader" type="button" class="menu-btn"
        @click="mobileMenuOpened = !mobileMenuOpened"></button>
      <div class="header__wrap" :class="{ 'header__wrap--opened': mobileMenuOpened }">
        <div v-if="!$route.meta.shortHeader" class="logo">
          <a href="https://medtech.moscow/" target="_blank" rel="nofollow">
            <img src="@/assets/img/images/logo-1.svg" alt="Московский центр иновационных технологий в здравоохранении"
              width="288" height="60">
          </a>
          <img src="@/assets/img/images/logo-2.svg" alt="Комплекс социального развития Москвы" width="138" height="54">
        </div>
        <div v-else class="header__logo">
          <!--          <img src="@/assets/img/images/logo-1.png" alt="Московский центр иновационных технологий в здравоохранении" width="288" height="60">-->
        </div>

        <nav v-if="!$route.meta.shortHeader" class="menu">
          <ul class="menu__list">

            <li class="menu__item" v-for="item in menuItems" :key="item.label"
              :class="item.submenu?.length && 'menu__item--has-child'">
              <router-link :to="item.route" class="menu__link"
                :class="[currentSection === item.route.replace('/#', '') && 'menu__link--active']"
                @click="mobileMenuOpened = false">{{ item.label }}</router-link>

              <div class="menu__sub-wrap" v-if="item.submenu?.length">
                <ul class="menu__sub-list">
                  <li class="menu__sub-item" v-for="subitem in item.submenu" :key="subitem.label">
                    <router-link :to="subitem.route" class="menu__link"
                      @click.prevent="mobileMenuOpened = false">{{ subitem.label }}</router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
        <div v-if="!$route.meta.shortHeader" class="header__login" @click="popupOpened = false">
          <template v-if="!this.$store.state.user.authorized">
            <router-link :to="`/register`" :class="`header__link`">Регистрация</router-link>
            <router-link :to="`/login`" :class="`header__link`">Вход</router-link>
          </template>
          <template v-else>
            <router-link :to="`/profile`" class="header__user">
              <span>{{ getName }}</span>
            </router-link>
            <a @click="$store.dispatch('user/logout')" class="header__logout">выйти</a>
          </template>
        </div>
        <!--        <div v-else class="header__login">
          <span class="header__user">
            <span>{{ getName }}</span>
          </span>
        </div>-->
        <RouterLink v-if="$route.meta.shortHeader" to="/" class="header__back">Вернуться на главную</RouterLink>
        <div v-if="!$route.meta.shortHeader" class="header__contacts">
          <a href="mailto:support@medtech.ru">support@medtech.ru</a>
          <a href="tel:+74956503232">8-495-650-32-32</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderLayout",
  computed: {
    getName() {
      const { firstName, lastName, middleName } = this.$store.state.user.userFields;
      if (firstName && lastName && middleName) {
        return `${lastName} ${firstName[0]}. ${middleName[0]}.`
      }
      return 'Профиль';
    },
    userIsVerified() {
      return this.$store.state.user.userFields?.isVerified
        && (this.$store.state.user.userFields?.status === 'Верифицирован из списка')
    },
    menuItems() {
      if (this.userIsVerified) return this.menu;

      return this.menu.filter(el => !el.verifiedOnly)
    }
  },
  data() {
    return {
      mobileMenuOpened: false,
      currentSection: '',
      menu: [
        {
          route: '/#home',
          label: 'Главная'
        },
        {
          route: '/#school',
          label: 'Школа',
          submenu: [
            {
              route: '/#newevents',
              label: 'Будущие мероприятия',
            },
            {
              route: '/#oldevents',
              label: 'Прошедшие мероприятия',
            }
          ]
        },
        {
          route: '/#cert',
          label: 'GCP',
          verifiedOnly: true,
        },
        {
          route: '/#materials',
          label: 'Материалы'
        },
        {
          route: '/#sop',
          label: 'СОП'
        },
        {
          route: '/#media',
          label: 'Медиа'
        },
        {
          route: '/#contacts',
          label: 'Контакты'
        },
      ]
    }
  },
  methods: {
    createObserver(el, id) {
      const handleIntersect = (e) => {
        if (e[0].isIntersecting) {
          this.currentSection = id
          return
        }
      }

      const observer = new IntersectionObserver(handleIntersect, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      })

      observer.observe(el)
    }
  },
  async mounted() {
    await this.$nextTick();

    const sections = document.querySelectorAll('[data-nav-section]')

    sections.forEach(section => {
      const { id } = section

      this.createObserver(section, id)
    })
  },
  watch: {
    async $route() {
      await this.$nextTick();

      const sections = document.querySelectorAll('[data-nav-section]')

      sections.forEach(section => {
        const { id } = section

        this.createObserver(section, id)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.header__user {
  padding-right: 0;
  margin-right: -10px;
  border-bottom: 1px dashed;
  padding-bottom: 2px;
}

.header__user:hover {
  border-bottom: 1px transparent;
}

.header__user::after {
  display: none;
}


.menu__link {
  position: relative;

  &::before {
    content: '';
    transition: 500ms;
    opacity: 0;

    @media (max-width: 1200px) {
      content: none;
    }
  }
}

.menu__item {
  &--has-child {
    display: flex;
    gap: 8px;
    align-items: center;

    &::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L4.5 4L8 1' stroke='%23333333'/%3E%3C/svg%3E%0A");
      width: 10px;
      height: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      @media (max-width: 1200px) {
        content: none;
      }
    }
  }

  &:hover {
    .menu__sub-wrap {
      display: block;
    }
  }
}

.menu__link--active {
  &::before {
    opacity: 1;
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% - 2px);
    height: 2px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #000;
    transition: 500ms;
  }
}

.menu__sub-wrap {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid rgba(250, 250, 250, 0.7);
}

.menu__sub-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  width: 1200px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  gap: 24px;
}
</style>