<template>
    <div class="review-form">
        <form class="review-form__wrap" @submit.prevent="sendRequest">
            <div class="review-form__title">
                Записаться на&nbsp;модульный онлайн-тренинг подготовки исследовательских центров к&nbsp;клиническим
                исследованиям
            </div>

            <template v-if="!success">

                <div class="review-form__select">
                    <label for="event_review" class="review-form__select-label" v-show="!educationSurveyTheme_ids">
                        Выберите курс
                    </label>
                    <select id="event_review" class="review-form__select-control" v-model="educationSurveyTheme_ids"
                        :class="error && !educationSurveyTheme_ids && 'error'">
                        <option :value="event.id" v-for="event in themes" :key="event.id">
                            {{ event.name }}
                        </option>
                    </select>
                </div>

                <input :class="error && !orgName && 'error'" type="text" class="review-form__control" v-model="orgName"
                    placeholder="Название исследовательского центра">

                <input :class="error && !participantsNumber && 'error'" type="number" class="review-form__control" v-model="participantsNumber"
                    placeholder="Количество участвующих">

                <input :class="error && !dateAndTime && 'error'" type="datetime-local" class="review-form__control" v-model="dateAndTime"
                    placeholder="Предложите удобные дату и время (курс длится 1 час 20 мин);">

                <input :class="error && !contacts && 'error'" type="text" class="review-form__control" v-model="contacts"
                    placeholder="Контакты ответственного за группу (ФИО, телефон, e-mail)">

                <button class="review-form__btn" :disabled="!userIsVerified" v-if="userIsAuthorized">
                    Записаться
                </button>
                <RouterLink to="/login" class="review-form__btn" v-else>
                    Войти и записаться
                </RouterLink>
            </template>

            <template v-else>
                <div class="review-form__success">
                    Спасибо за запись
                </div>
            </template>
        </form>
    </div>
</template>

<script setup>
import moment from 'moment-with-locales-es6';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps(['show'])

const store = useStore()

const orgName = ref('')
const participantsNumber = ref('')
const dateAndTime = ref('')
const contacts = ref('')

const success = ref(false)
const educationSurveyTheme_ids = ref()
const error = ref(false)

let themes = computed(() => {
    console.log(store.state.educationSurvey.themes)
    return store.state.educationSurvey.themes
})

const userIsAuthorized = computed(() => {
    return store.state.user.authorized || false
})

const userIsVerified = computed(() => {
    return store.state.user.userFields.isVerified || false
})

const sendRequest = async () => {
    error.value = false

    if (!userIsVerified.value || !educationSurveyTheme_ids.value || !orgName.value || !participantsNumber.value || !dateAndTime.value || !contacts.value) {
        error.value = true
        return
    }

    const data = {
        orgName: orgName.value,
        participantsNumber: participantsNumber.value,
        dateAndTime: moment(dateAndTime.value).clone().format("YYYY-MM-DD HH:mm"),
        contacts: contacts.value,
        educationSurveyTheme_ids: educationSurveyTheme_ids.value
    }

    const result = await store.dispatch('educationSurvey/sendEduRequest', data);

    if (result.success) {
        success.value = true
        orgName.value = ''
        participantsNumber.value = ''
        dateAndTime.value = ''
        contacts.value = ''
        educationSurveyTheme_ids.value = undefined
    }
}

watch(() => props.show, () => {
    success.value = false
})
</script>


<style lang="scss" scoped>
.review-form {

    &__select {
        position: relative;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            margin-bottom: 10px;
        }

        &-label {
            position: absolute;
            top: 0;
            bottom: 0;
            color: #c2c2c2;
            height: fit-content;
            margin: auto;
            left: 14px;
        }

        &-control {
            border: 1px solid rgba(219, 219, 219, 0.5);
            border-radius: 10px;
            padding: 14px;
            background: transparent;
            resize: none;
            width: 100%;
            text-align: left;
            appearance: none;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 16 28' fill='none' transform='rotate(90)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 26L13.5 14L1.5 2' stroke='%23000' stroke-width='3' stroke-linecap='round' /%3E%3C/svg%3E%0A");
            background-position: calc(100% - 14px);
            background-repeat: no-repeat;
            transition: 500ms;

            @media (max-width: 768px) {
                padding: 16px 20px;
            }
        }
    }

    &__wrap {
        background-color: #fff;
        padding: 50px 60px;
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.11);
        border-radius: 30px;
        max-height: 98dvh;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 3px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #094df1;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
            background-color: #f5f5f5;
        }


        @media (max-width: 768px) {
            width: 95%;
            margin: auto;
            padding: 20px;
        }
    }


    &__title {
        font-size: 30px;
        line-height: 110%;
        margin-top: 0;
        margin-bottom: 60px;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    &__count {
        font-size: 16px;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        list-style: none;
        padding: 0;
        margin: 0;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }

    &__control {
        border: 1px solid rgba(219, 219, 219, 0.5);
        border-radius: 10px;
        padding: 14px;
        background: transparent;
        resize: none;
        width: 100%;
        text-align: left;
        appearance: none;
        margin-bottom: 20px;
        transition: 500ms;

        &:focus {
            border-color: #094df1;

            &::placeholder {
                color: #094df1;
            }
        }

        @media (max-width: 768px) {
            padding: 16px 20px;
            margin-bottom: 10px;
        }

        &::placeholder {
            color: #c2c2c2;
        }
    }


    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1274E0;
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        max-width: 300px;
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        letter-spacing: 0.02em;
        margin: auto;

        &:disabled {
            filter: grayscale(100);
            opacity: 0.5;
            cursor: not-allowed;
        }

        @media (max-width: 768px) {
            max-width: 100%;
        }

    }

    &__success {
        font-size: 24px;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 16px;
        }

    }

    .error {
        border-color: red;
        transition: 500ms;
    }

}
</style>